import Util from '@lcs/utils'
import CryptoJS from 'crypto-js'
import md5 from 'js-md5'

/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param t
 * @returns {Function}
 * @constructor
 */
export const Debounce = (fn, t = 500) => {
  let timer
  return function () {
    const args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, args)
    }, t)
  }
}

/**
 * 函数节流（规定时间内只一次生效）
 * @param fn
 * @param t
 * @returns {Function}
 * @constructor
 */
export const Throttle = (fn, t = 500) => {
  let timer
  let prev = 0

  return function () {
    const now = Date.now()
    const remaining = t - (now - prev)
    const args = arguments
    if (remaining <= 0 || remaining > t) {
      if (timer) {
        clearTimeout(timer)
        timer = null
      }
      prev = now
      fn.apply(this, args)
    } else if (!timer) {
      timer = setTimeout(() => {
        prev = Date.now()
        timer = null
        fn.apply(this, args)
      }, remaining)
    }
  }
}

/**
 * 动态加载js
 * @param {*} url
 * @param {*} callback
 */
export const loadJS = (url, callback) => {
  var script = document.createElement('script')
  script.type = 'text/javascript'
  // eslint-disable-next-line no-new-func
  script.callback = typeof callback !== 'undefined' ? callback : new Function()
  script[document.all ? 'onreadystatechange' : 'onload'] = function () {
    if (
      document.all &&
      // eslint-disable-next-line eqeqeq
      this.readyState != 'loaded' &&
      // eslint-disable-next-line eqeqeq
      this.readyState != 'complete'
    ) {
      return
    }
    this.callback(this)
    this.callback = null
    this[document.all ? 'onreadystatechange' : 'onload'] = null
    this.parentNode.removeChild(this)
  }
  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

const toString = Object.prototype.toString
const isType = type => {
  return function (obj) {
    return toString.call(obj) === '[object ' + uFirst(type) + ']'
  }
}

const uFirst = str => {
  str += ''
  // eslint-disable-next-line eqeqeq
  if (str != '') {
    return str[0].toUpperCase() + str.substring(1)
  }
  return ''
}

/**
 * 是否是数组
 */
export const isArray = Array.isArray || isType('array')

/**
 * 是否是函数
 */
export const isFunction = isType('function')

/**
 * 是否是对象
 */
export const isObject = isType('object')

/**
 * 获取当前设备下的px值，
 * 主要用于canvas及各种无法设置rem的情况，手动计算适配各种设备
 * （依赖于flexible.js）
 * @param {*} px
 * @param {*} rem 默认75
 */
export const getCurrentPx = (px, rem = 75) => {
  return (parseFloat(document.documentElement.style.fontSize) * px) / rem
}

/**
 * fn在rem设置宽度后执行，当fn需要用到页面元素的宽高时使用
 * 依赖于flexible.js）
 * @param Function fn
 * @param {*} fn参数
 */
export const remAfter = function (fn, ...args) {
  const flexible = (window.lib || {}).flexible || {}
  if (flexible.rem > 0) {
    return fn.apply(this, args)
  } else {
    setTimeout(() => {
      return fn.apply(this, args)
    }, 350)
  }
}

export const birthdays = (birthdays) => {
  const d = new Date()
  return d.getFullYear() - birthdays.getFullYear() - (d.getMonth() < birthdays.getMonth() ||
    (d.getMonth() === birthdays.getMonth() &&
      d.getDate() < birthdays.getDate())
    ? 1
    : 0)
}
/**
 * 校验15位或者18位身份证
 * @param {*} num 输入的身份证号码
 */
export const checkId = num => {
  num = num.toUpperCase()
  // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
  if (!/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num)) {
    // eslint-disable-next-line no-console
    // console.log('输入的身份证号长度不对，或者号码不符合规定！\n15位号码应全为数字，18位号码末位可以为数字或X。');
    return IsHKID(num)
  }
  // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
  // 下面分别分析出生日期和校验位
  var len, re
  len = num.length
  if (len === 15) {
    re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/)
    var arrSplit = num.match(re)

    // 检查生日日期是否正确
    var dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4])
    var bGoodDay
    bGoodDay =
      dtmBirth.getYear() === Number(arrSplit[2]) && dtmBirth.getMonth() + 1 === Number(arrSplit[3]) && dtmBirth.getDate() === Number(arrSplit[4])
    if (!bGoodDay) {
      // eslint-disable-next-line no-console
      console.log('输入的身份证号里出生日期不对！')
      return false
    } else {
      // 将15位身份证转成18位
      // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      var arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
      var arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
      var nTemp = 0
      num = num.substr(0, 6) + '19' + num.substr(6, num.length - 6)
      for (let i = 0; i < 17; i++) {
        nTemp += num.substr(i, 1) * arrInt[i]
      }
      num += arrCh[nTemp % 11]
      return true
    }
  }
  if (len === 18) {
    re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/)
    arrSplit = num.match(re)

    // 检查生日日期是否正确
    dtmBirth = new Date(arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4])
    bGoodDay =
      dtmBirth.getFullYear() === Number(arrSplit[2]) && dtmBirth.getMonth() + 1 === Number(arrSplit[3]) && dtmBirth.getDate() === Number(arrSplit[4])
    if (!bGoodDay) {
      // eslint-disable-next-line no-console
      console.log('输入的身份证号里出生日期不对！')
      return false
    } else {
      // 检验18位身份证的校验码是否正确。
      // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      var valnum
      arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
      arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
      nTemp = 0
      for (let i = 0; i < 17; i++) {
        nTemp += num.substr(i, 1) * arrInt[i]
      }
      console.log(nTemp, nTemp % 11)
      valnum = arrCh[nTemp % 11]
      console.log(valnum, num.substr(17, 1))
      if (valnum !== num.substr(17, 1)) {
        // eslint-disable-next-line no-console
        console.log('18位身份证的校验码不正确！应该为：' + valnum)
        return false
      }
      return true
    }
  }
  return false
}

/**
 * 校验是否为身份证
 * @param {*} str 输入的身份证号码
 */
export const IsHKID = str => {
  var strValidChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  // basic check length
  if (str.length < 8) return false
  // handling bracket=
  if (str.charAt(str.length - 3) === '(' && str.charAt(str.length - 1) === ')') str = str.substring(0, str.length - 3) + str.charAt(str.length - 2)
  // convert to upper case
  str = str.toUpperCase()
  // regular expression to check pattern and split
  var hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/
  var matchArray = str.match(hkidPat)
  // not match, return false
  if (matchArray == null) return false
  // the character part, numeric part and check digit part
  var charPart = matchArray[1]
  var numPart = matchArray[2]
  var checkDigit = matchArray[3]
  // calculate the checksum for character part
  var checkSum = 0
  if (charPart.length === 2) {
    checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)))
    checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)))
  } else {
    checkSum += 9 * 36
    checkSum += 8 * (10 + strValidChars.indexOf(charPart))
  }
  // calculate the checksum for numeric part
  for (var i = 0, j = 7; i < numPart.length; i++, j--) checkSum += j * numPart.charAt(i)
  // verify the check digit
  var remaining = checkSum % 11
  var verify = remaining === 0 ? 0 : 11 - remaining
  return verify === checkDigit || (verify === 10 && checkDigit === 'A')
}
/**
 * @description: 获取url参数
 * @param {*} variable
 * @return {*}
 */
export function GetQueryValue1 (variable) {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] === variable) { return pair[1] }
  }
  return (false)
}

/**
 * [通过参数名获取url中的参数值]
 * 示例URL:http://htmlJsTest/getrequest.html?uid=admin&rid=1&fid=2&name=小明
 * @param  {[string]} queryName [参数名]
 * @return {[string]}           [参数值]
 */
export function getQueryVariable (queryName) {
  if (window.location.href.indexOf('#') !== -1) {
    const hash = window.location.href.split('#')[1]
    const search = hash.indexOf('?') !== -1 ? hash.split('?')[1] : ''
    var vars = search.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      if (pair[0] === queryName) { return pair[1] }
    }
    return (false)
  } else {
    return GetQueryValue1(queryName)
  }
}

export function getQueryObject () {
  const str = window.location.href
  if (str.indexOf('?') === -1) return {}
  const arr = str.split('?')[1].split('&') // 先通过？分解得到？后面的所需字符串，再将其通过&分解开存放在数组里
  const obj = {}
  for (const i of arr) {
    obj[i.split('=')[0]] = i.split('=')[1] // 对数组每项用=分解开，=前为对象属性名，=后为属性值
  }
  return obj
}
// 解决微信端字体改变导致页面的问题
export function wxFontSizeReset () {
  // eslint-disable-next-line no-undef
  if (typeof WeixinJSBridge === 'object' && typeof WeixinJSBridge.invoke === 'function') {
    handleFontSize()
  } else {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', handleFontSize, false)
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', handleFontSize)
      document.attachEvent('onWeixinJSBridgeReady', handleFontSize)
    }
  }
}

function handleFontSize () {
  // eslint-disable-next-line no-undef
  WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
  // eslint-disable-next-line no-undef
  WeixinJSBridge.on('menu:setfont', function () {
    // eslint-disable-next-line no-undef
    WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
  })
}

export function setLiveSource (data, isIOS = Util.os.isIOS, map = {
  original: '原画',
  ud: '超清',
  hd: '高清',
  sd: '标清',
  ld: '流畅'
}) {
  let type = isIOS ? 'm3u8' : 'flv'
  if (sessionStorage.getItem('m3u8')) {
    type = 'm3u8'
  }
  let video = data
  if (typeof data === 'object' && data !== null) {
    video = []
    for (const key in map) {
      if (data[key]) {
        video.push({
          name: map[key],
          url: data[key][type]
        })
      }
    }
  }
  return video
  // switch (typeof data) {
  //   case 'object':
  //     video = []
  //     for (const key in map) {
  //       if (data[key]) {
  //         video.push({
  //           name: map[key],
  //           url: data[key][type]
  //         })
  //       }
  //     }
  //     break
  //   default:
  //     break
  // }
  // return video
}

// exclude里面的值不进行深度克隆
export function deepClone (source, exclude = []) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(key => {
    if (source[key] && typeof source[key] === 'object' && !exclude.includes(key)) {
      targetObj[key] = deepClone(source[key], exclude)
    } else {
      targetObj[key] = source[key]
    }
  })
  return targetObj
}
// 解密
export const aesDecrypt = (word, obj = {}) => {
  const encryptedHexStr = CryptoJS.enc.Hex.parse(word)
  const encodedString = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decryptedResults = CryptoJS.AES.decrypt(encodedString, obj.key, obj.obsfucationConfig)
  return String(decryptedResults.toString(CryptoJS.enc.Utf8))
}

// 加密
export const aesEncrypt = (word, obj = {}) => {
  if (word) {
    const encodedString = CryptoJS.enc.Utf8.parse(word)
    const encryptedResults = CryptoJS.AES.encrypt(encodedString, obj.key, obj.obsfucationConfig)
    return encryptedResults.ciphertext.toString().toUpperCase()
  } else {
    return ''
  }
}

// 发送验证码 加密手机号
export function signSms (phone, source, timestamp) {
  return md5(`phone=${phone}&source=${source}&timestamp=${timestamp}`.toLocaleLowerCase() + 'qjbx20220323yanfaerzhongxin-md5')
}

export function CBCPhone (phone) {
  const vector = CryptoJS.enc.Utf8.parse('6622784059170375')
  const key = CryptoJS.enc.Utf8.parse('Gm?g,F_EHr}9*$`(A4SS<LCleWhDwkt3')
  const obsfucationConfig = {
    iv: vector, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7
  }
  return aesEncrypt(phone, { key, obsfucationConfig })
}
