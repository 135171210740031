import http from '@/utils/axios'

// 保险计划书产品详情
export async function getPlanProductInfo (protectId) {
  console.log(protectId)
  try {
    const res = await http.get('/api/api/planProductInfo', {
      params: { protect_id: protectId }
    })
    return res
  } catch (error) {
    console.log('getPlanProductInfo -> error', error)
  }
}
// 产品列表
export async function getPlanProductList () {
  try {
    const res = await http.get('/api/api/planProductList', {
      params: {}
    })
    return res
  } catch (error) {
    console.log('getPlanProductList -> error', error)
  }
}
// 保险计划书列表
export async function getPlanList (opt) {
  try {
    const res = await http.get('/api/api/planList', {
      params: opt
    })
    return res
  } catch (error) {
    console.log('getPlanList -> error', error)
  }
}

// 保险计划书详情接口
export async function getPlanInfo (data) {
  try {
    const res = await http.post('/api/api/planInfo', data)
    return res
  } catch (error) {
    console.log('getPlanInfo -> error', error)
  }
}
// 保险计划书费率计算接口
// rest 为保障责任，动态渲染，后端配置
export async function getPlanRateCalcula (data) {
  const res = await http.post('/api/api/planRateCalcula', data)
  return res
}
// 保险计划书添加
export async function addPlan (options) {
  try {
    const res = await http.post('/api/api/addPlan', options)
    return res
  } catch (error) {
    console.log('addPlan -> error', error)
  }
}
// 保险计划书删除
export async function delPlan ({ plan_id: planId }) {
  try {
    const res = await http.post('/api/api/delPlan', { plan_id: planId })
    return res
  } catch (error) {
    console.log('delPlan -> error', error)
  }
}

export async function planAssemble () {
  try {
    const res = await http.post('/api/api/planAssemble', {})

    return res
  } catch (error) {
    console.log('delPlan -> error', error)
  }
}
