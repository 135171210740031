
// eslint-disable-next-line no-unused-vars
import { getPlanList, getPlanProductList, getPlanProductInfo, getPlanRateCalcula, delPlan, addPlan } from '@/api/insurancePlan.js'
import dateUtils from '@lcs/utils/dist/dateUtils'
const DEFAULT_USER_INFO = {
  age: '',
  gender: 'm',
  plan_insured_name: '',
  plan_name: ''
}
const state = {
  uid: '', // 用户id
  plan_id: '',
  protectInfo: {
    sum_insured: '',
    protect_period_title: '',
    total_premium: '',
    pay_period_title: ''
  }, // 保障责任,用于显示在选择的产品信息中，在确定费用之后显示
  // 当前被保人信息
  currentUserInfo: {
    age: '1980-01',
    gender: 'm',
    plan_insured_name: '',
    plan_name: ''
  },
  // 当前编辑的产品信息
  currentProductInfo: {
    payPeriod: '', // 缴费期间
    product_id: '',
    productName: '',
    // uid: '',
    max_age: '',
    min_age: '',
    // 保障责任
    protectPeriod: '',
    sumInsured: '',
    totalPremium: '',
    protectPeriodTitle: '',
    sumInsuredTitle: '',
    totalPremiumTitle: ''
  },
  planList: [], // 计划书列表
  productList: [], // 产品列表
  money: null, // 费用
  isEdit: false // 判断是否是编辑产品
}

const mutations = {
  SET_IS_EDIT (state, val) {
    state.isEdit = val
  },
  SET_UID (state, uid) {
    state.uid = uid
  },
  SET_SET_PRODUCT_LIST_ITEM_MONEY (state, id, money) {
    state.productList.forEach(item => {
      if (item.product_id === id) {
        item.total_premium = money
      }
    })
  },
  SET_PRODUCT_LIST (state, protectList) {
    state.productList = protectList
  },
  PUSH_PRODUCT_LIST (state, protectList) {
    state.productList.push(protectList)
  },
  SETPRODUCT_INFO_TOTAL_MONEY (state, money) {
    state.currentProductInfo.totalPremium = money
  },
  SET_PROTECT_INFO (state, val) {
    state.protectInfo = val
  },
  SET_PLAN_ID (state, id) {
    state.plan_id = id
  },
  SET_PLAN_LIST (state, list) {
    state.planList = list
  },
  SET_USER_INFO: (state, option) => {
    state.currentUserInfo = option
  },
  SET_PRODUCT_INFO: (state, val) => {
    state.currentProductInfo = val
  },

  SET_MONEY: (state, val) => {
    state.money = val
  }
}

const actions = {
  // 初始化数据，防止页面不刷新，导致脏数据
  initData ({ commit }) {
    commit('SET_USER_INFO', DEFAULT_USER_INFO)
    commit('SET_PRODUCT_INFO', {})
    commit('SET_PRODUCT_LIST', [])
    commit('SET_MONEY', '')
    commit('SET_PLAN_ID', null)
    commit('SET_PROTECT_INFO', {})
  },
  async getPlanList ({ commit }, uid) {
    try {
      const res = await getPlanList({ bind_uid: uid })
      if (res.code === 0) {
        res.data && res.data.data && res.data.data.forEach(item => {
          item.c_time = dateUtils.format(new Date(item.c_time.replace(/-/g, '/')))
        })
        commit('SET_PLAN_LIST', res.data || [])
        return { code: 0 }
      } else {
        return {
          code: res.code,
          error: res.msg
        }
      }
    } catch (error) {
      return {
        code: 1, error
      }
    }
  },
  async addPlan ({ commit }, options) {
    return addPlan({ ...options, bind_uid: state.uid })
  },
  async delPlan ({ commit }, planId) {
    if (!planId) return
    return delPlan({ plan_id: planId })
  },
  setProductInfo ({ commit }, option) {
    commit('SET_PRODUCT_INFO', option)
  },
  async setMoney ({ commit },
    {
      age,
      gender,
      product_data: productList = this.state.insurancePlan.productList
    }) {
    try {
      productList.forEach(item => { item.message = '' })
      return await getPlanRateCalcula({ age, gender, product_data: productList })
    } catch (error) {
      return {
        code: -1,
        msg: error,
        data: {
          product_data: []
        }
      }
    }
  },
  setProductList ({ commit }, { type, arg }) {
    let list = state.productList
    if (type === 'add') {
      commit('PUSH_PRODUCT_LIST', arg)
    } else if (type === 'del') {
      list = list.filter(item => {
        return item.product_id !== arg
      })
      commit('SET_PRODUCT_LIST', list)
    } else if (type === 'edit') {
      list = list.map(item => {
        if (item.product_id === arg.product_id) {
          return arg
        }
        return item
      })
      commit('SET_PRODUCT_LIST', list)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
