/* eslint-disable no-useless-escape */
import sa from 'sa-sdk-javascript'
import Util from '@lcs/utils'

export default function install (Vue, config = {}) {
  const isPush = window.location.href.indexOf('is_push=1') !== -1 ? '1' : ''
  const pushType = window.location.href.indexOf('push_type=1') !== -1 ? '微信文本' : (window.location.href.indexOf('push_type=2') !== -1 ? '微信图片' : (window.location.href.indexOf('push_type=3') !== -1 ? '微信图文消息' : (window.location.href.indexOf('push_type=4') !== -1 ? '模板消息' : '')))
  // eslint-disable-next-line no-sparse-arrays
  const pushTitle = decodeURIComponent((new RegExp('[?|&]' + 'push_title' + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [, ''])[1].replace(/\+/g, '%20')) || ''
  if (install.installed) return
  install.installed = true
  sa.init(config)

  const defaultOption = {
    v1_element_content: '',
    v1_message_title: '',
    v1_message_id: '',
    v1_message_type: '',
    v1_symbol: '',
    v1_stock_name: '',
    v1_lcs_name: '',
    v1_lcs_id: '',
    v1_source: '',
    v1_order: '',
    v1_share_channel: '',
    v1_paying_user: '',
    v1_page_title: '',
    v1_environment: Util.sinaApp.isLicaishi ? '0' : (Util.sinaApp.isMiniProgram ? '1' : (Util.sinaApp.isLicaishiAdmin ? '3' : (Util.sinaApp.isWeixin ? '5' : '2'))),
    v1_page_url: window.location.href,
    deviceId: '',
    sc_comments: '',
    visit_time: `${Date.now()}`.slice(0, 10),
    v1_is_push: isPush,
    v1_push_type: pushType,
    v1_push_title: pushTitle
  }

  sa.quick('autoTrack')

  Object.defineProperty(Vue.prototype, '$sa', {
    get: () => sa
  })

  sa.click = function (data, type = 'BXClick') {
    const option = getOption(data)
    sa.track(type, option)
  }

  sa.visit = function (data, type = 'BXVisit') {
    const option = getOption(data)
    sa.track(type, option)
    resetPush()
  }

  sa.actClick = function (data, type = 'BXh5activityClick') {
    const option = getOption(data)
    sa.track(type, option)
    resetPush()
  }

  sa.actVisit = function (data, type = 'BXh5activityVisit') {
    const option = getOption(data)
    sa.track(type, option)
    resetPush()
  }
  sa.BXactivityVisit = function (data, type = 'BXactivityVisit') {
    const option = getOption(data)
    sa.track(type, option)
    resetPush()
  }
  function getOption (data) {
    return Object.assign(window.saRequestTdInitData || {}, defaultOption, data)
  }
  function resetPush () {
    defaultOption.v1_is_push = ''
    defaultOption.v1_push_type = ''
    defaultOption.v1_push_title = ''
  }
}
