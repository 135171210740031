; (function (window) {
  window.DEBUG = false
  var callbacks = {}
  var guid = 0

  var ua = navigator.userAgent
  var ANDROID = /bx_android/i.test(ua) || /android/i.test(ua)
  var IOS = /bx_iOS/i.test(ua) || /iphone|ipad/i.test(ua)
  var QJZS = /bx_qjzs_android/i.test(ua)

  /**
   * 方便在各个平台中看到完整的log
   */

  function log () {
    // eslint-disable-next-line no-undef
    if (DEBUG) {
      // eslint-disable-next-line no-useless-call
      console.log.call(console, Array.prototype.join.call(arguments, ''))
    }
  }

  /**
   * 平台相关的web 与  Native 单向通信方法
   */

  function invoke (cmd) {
    log('invoke', cmd)
    var protocol = 'qjbxinside'
    if (QJZS) protocol = 'qjzsbxinside'
    if (QJZS && window.AppBridge) {
      window.AppBridge.postMessage(cmd)
    } else if (ANDROID) {
      prompt(cmd)
    } else if (IOS) {
      console.log(
        protocol + '://bridge2.native?params=' + encodeURIComponent(cmd)
      )
      js2native(
        protocol + '://bridge2.native?params=' + encodeURIComponent(cmd)
      )
    }
  }

  /**
   *平台交互调用方法
   */

  var BxBridge = {
    callByJS: function (opt) {
      log('callByJS', JSON.stringify(opt))
      var input = {}
      input.name = opt.name
      input.token = ++guid
      input.param = opt.param || opt.params || {}
      callbacks[input.token] = opt.callback
      invoke(JSON.stringify(input))
    },
    callByNative: function (opt) {
      log('callByNative', JSON.stringify(opt))
      var callback = callbacks[opt.token]
      var ret = opt.ret || {}
      var script = opt.script || ''
      if (script) {
        // Native 主动调用web
        log('callByNative script', script)
        try {
          invoke(
            JSON.stringify({
              token: opt.token,
              // eslint-disable-next-line no-eval
              ret: eval(script)
            })
          )
        } catch (e) {
          console.error(e)
        }
      } else if (callback) {
        // web 主动调用Native,Native 被动响应
        callback(ret)
        try {
          delete callbacks[opt.token]
          log(callbacks)
        } catch (e) {
          console.error(e)
        }
      }
    }
  }

  function js2native (url) {
    var iframe = document.createElement('iframe')
    iframe.src = url
    iframe.style.display = 'none'
    document.body.appendChild(iframe)
    iframe.parentNode.removeChild(iframe)
    iframe = null
  }

  window.BxBridge = BxBridge
  window.AppBridge = Object.assign(window.AppBridge || {}, BxBridge)

  window.__log = log
})(window)

export default window.BxBridge
