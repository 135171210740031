import Request from '@lcs/http'
// import Request from './request'
import config from '@/config'
import getHeader from './header'
// eslint-disable-next-line no-unused-vars
import errorHandlers from './errorHandlers'
import { getToken } from '@/utils/auth'
import BxUtil, { getCookies } from '@/utils/BxUtil'
import Vue from 'vue'
const platform = BxUtil.getPlatform()
const vm = new Vue()
const showToast = (txt) => {
  vm.$createToast({
    time: 2000,
    txt,
    type: 'error'
  }).show()
}

const lcsAxiosConfig = {
  errorHandlers,
  cancelDuplicated: false,
  netErrorTip: true,
  codeErrorTip: true,
  codeErrorTipFn: (msg) => {
    showToast(msg)
  },
  netErrorTipFn: (msg) => {
    showToast(msg)
  }
}
const axiosConfig = {
  baseURL: config.baseApi
}
export default new Request(
  {
    ...lcsAxiosConfig,
    beforeHook: async (config) => {
      config.headers['access-token'] = getToken() || await getCookies('access-token')
      const headers = getHeader()
      if (!config.params) {
        config.params = { platform }
      } else if (!config.params.platform) {
        config.params.platform = platform
      }

      config.headers = Object.assign({}, config.headers, headers)
    }
  }, axiosConfig)
