const getters = {
  pushInfo: state => state.chat.pushInfo,
  msgList: state => state.chat.msgList,
  newMsgData: state => state.chat.newMsgData,
  msgType: state => state.chat.msgType,
  activityData: state => state.chat.activityData,
  wxInfo: state => state.user.wxInfo,
  RHwxInfo: state => state.user.RHwxInfo,
  planList: state => state.insurancePlan.planList.data || [],
  activity: state => state.activity.activity
}

export default getters
