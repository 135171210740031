/* eslint-disable no-unused-vars */
// 企业微信和微信区分， 企业微信通过cdn引入，window.wx
import wx from 'weixin-js-sdk'
import { loadJS, getQueryVariable } from '@/utils/common'
import jsonp from '@/utils/jsonp'
import { externalUserInfo } from '@/api/weixin'
// import config from '@/config'
import Util from '@lcs/utils'
import { isInWXWork } from '@/utils/navigator'
import Vue from 'vue'
import BxUtil from '@/utils/BxUtil'
import { getJsApiConfig } from '@/api/getJsApiConfig'
import Store from '../store'
const debug = getQueryVariable('debug')
console.log('debug: ', debug)
const NODE_ENV = process.env.NODE_ENV
export async function wxConfig (share) {
  if (isInWXWork) {
    goQWShare(share)
  } else {
    goShare(share)
  }
}

export async function wxInit () {
  return new Promise((resolve, reject) => {
    if (!BxUtil.isQychart()) {
      getJsApiConfig(0).then(res => {
        if (res && res.code === 0) {
          console.error('wxInit -> res', res)
          onWxConfig(res.data)
        } else {
          console.error('%c [ wxInit ]', 'font-size:13px; background:pink; color:#bf2c9f;', res)
        }
      })
    }
  })
}

export async function qywxInit () {
  return new Promise((resolve, reject) => {
    if (BxUtil.isQychart()) {
      getJsApiConfig(0).then(res => {
        loadJS('//res.wx.qq.com/open/js/jweixin-1.2.0.js', () => {
          loadJS('//open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js', () => {
            onQyWxConfig(res.data, resolve, reject)
          })
        })
      })
    }
  })
}
function goQWShare (shareInfo) {
  // wx.ready(function () {
  // 转发
  window.wx.onMenuShareAppMessage({
    title: shareInfo.title, // 分享标题
    desc: shareInfo.desc, // 分享描述
    link: shareInfo.link, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
    imgUrl: shareInfo.imgUrl, // 分享图标
    success: function () {
      // 用户确认分享后执行的回调函数
      console.log('分享成功!')
    },
    cancel: function (res) {
      // 用户取消分享后执行的回调函数
      console.log('取消分享到朋友圈返回的信息为', res)
    }
  })
  // // 分享到微信
  // wx.onMenuShareWechat({
  //   title: shareInfo.title, // 分享标题
  //   desc: shareInfo.desc, // 分享描述
  //   link: shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
  //   imgUrl: shareInfo.imgUrl, // 分享图标
  //   success: function (res) {
  //     console.log('分享成功!')
  //   },
  //   cancel: function (res) {
  //     // 用户取消分享后执行的回调函数
  //     console.log('取消分享到微信返回的信息为', res)
  //   }
  // })
  // 分享给朋友圈
  window.wx.onMenuShareTimeline({
    title: shareInfo.title, // 分享标题
    desc: shareInfo.desc, // 分享描述
    link: shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
    imgUrl: shareInfo.imgUrl, // 分享图标
    type: '', // 分享类型,music、video或link，不填默认为link
    dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
    success: function (res) {
      console.log('分享成功!', res)
    },
    fail: function () {
      console.log('onMenuShareAppMessage失败')
    },
    cancel: function (res) {
      // 用户取消分享后执行的回调函数
      console.log('取消分享给朋友圈返回的信息为', res)
    }
  })
  // })
}

function goShare (shareInfo) {
  wx.ready(function () {
    // 分享到朋友圈
    wx.onMenuShareTimeline({
      title: shareInfo.title, // 分享标题
      desc: shareInfo.desc, // 分享描述
      link: shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: shareInfo.imgUrl, // 分享图标
      success: function (res) {
        console.log('分享成功!')
      },
      cancel: function (res) {
        // 用户取消分享后执行的回调函数
        console.log('取消分享到朋友圈返回的信息为', res)
      }
    })
    // 分享给朋友
    wx.onMenuShareAppMessage({
      title: shareInfo.title, // 分享标题
      desc: shareInfo.desc, // 分享描述
      link: shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: shareInfo.imgUrl, // 分享图标
      type: '', // 分享类型,music、video或link，不填默认为link
      dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
      success: function (res) {
        console.log('分享成功!', res)
      },
      fail: function () {
        console.log('onMenuShareAppMessage失败')
      },
      cancel: function (res) {
        // 用户取消分享后执行的回调函数
        console.log('取消分享给朋友返回的信息为', res)
      }
    })
    // 分享到QQ
    wx.onMenuShareQQ({
      title: shareInfo.title, // 分享标题
      desc: shareInfo.desc, // 分享描述
      link: shareInfo.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: shareInfo.imgUrl, // 分享图标
      success: function (res) { },
      cancel: function (res) { }
    })
    // 分享到QQ空间
    wx.onMenuShareQZone({
      title: shareInfo.title, // 分享标题
      desc: shareInfo.desc, // 分享描述
      link: window.location.href.split('#')[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: shareInfo.imgUrl, // 分享图标
      success: function (res) { },
      cancel: function (res) { }
    })
  })
}
// 企业微信
function onQyWxConfig (config, resolve, reject) {
  console.log('%c [ 153 config ]', 'font-size:13px; background:pink; color:#bf2c9f;', config)
  window.wx.config({
    beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
    debug: debug || false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: config.appId, // 必填，企业微信的corpID
    timestamp: config.timestamp, // 必填，生成签名的时间戳
    nonceStr: config.nonceStr, // 必填，生成签名的随机串
    signature: config.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
    jsApiList:
      [
        'checkJsApi',
        'agentConfig',
        'chooseImage',
        'getLocalImgData',
        'onMenuShareAppMessage',
        'onMenuShareTimeline'
      ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
    success: function (res) {
      console.log('%c [ 172 success res ]', 'font-size:13px; background:pink; color:#bf2c9f;', res)
    },
    fail: function (res) {
      console.error('config失败', res)
    }
  })
  window.wx.ready(async function (e) {
    console.log('checkJsApi')
    try {
      window.wx.checkJsApi({
        jsApiList: [
          'chooseImage'
        ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: async (res) => {
          console.log('checkJsApi-success')
          const agentConfig = await getJsApiConfig()
          console.error(agentConfig)
          try {
            window.wx.agentConfig({
              corpid: agentConfig.data.appId, // 必填，企业微信的corpid，必须与当前登录的企业一致
              agentid: agentConfig.data.agent_id, // 必填，企业微信的应用id （e.g. 1000247）
              timestamp: agentConfig.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: agentConfig.data.nonceStr, // 必填，生成签名的随机串
              signature: agentConfig.data.signature, // 必填，签名
              jsApiList: [
                'getCurExternalContact'
              ], // 必填
              success: (res) => {
                console.log('%c [  agentConfig success res ]', 'font-size:13px; background:pink; color:#bf2c9f;', res)
                resolve('agentConfig Success')
                Store.dispatch('auth/setQywxUserId')
              },
              fail: (res) => {
                console.log('agentConfig-fail', res)
                if (res.errMsg.indexOf('function not exist') > -1) {
                  alert('版本过低请升级')
                }
              }
            })
          } catch (error) {
            reject('error', error)
            console.error('agentConfig -> error', error)
          }
        }
      })
    } catch (error) {
      console.error('checkJsApi -> error', error)
    }
  })

  window.wx.error(function (res) {
    console.log('%c [ wx Error ]', 'font-size:13px; background:pink; color:#bf2c9f;', res)
  })
  Vue.prototype.wx = window.wx
}

function onWxConfig (config, share) {
  wx.config({
    debug: debug || false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: config.appId, // 必填，公众号的唯一标识
    // appId: 'wx36ee943714067f6b', // 测试用
    timestamp: config.timestamp, // 必填，生成签名的时间戳，精确到秒
    nonceStr: config.nonceStr, // 必填，生成签名的随机串
    signature: config.signature, // 必填，签名
    jsApiList:
      [
        'getCurExternalContact',
        'checkJsApi',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo',
        'onMenuShareQZone',
        'hideMenuItems',
        'showMenuItems',
        'hideAllNonBaseMenuItem',
        'showAllNonBaseMenuItem',
        'translateVoice',
        'startRecord',
        'stopRecord',
        'onVoiceRecordEnd',
        'playVoice',
        'onVoicePlayEnd',
        'pauseVoice',
        'stopVoice',
        'uploadVoice',
        'downloadVoice',
        'chooseImage',
        'previewImage',
        'uploadImage',
        'downloadImage',
        'getNetworkType',
        'openLocation',
        'getLocation',
        'hideOptionMenu',
        'showOptionMenu',
        'closeWindow',
        'scanQRCode',
        'chooseWXPay',
        'openProductSpecificView',
        'addCard',
        'chooseCard',
        'openCard',
        'getLocalImgData'
      ]
  })
  Vue.prototype.wx = wx
}
