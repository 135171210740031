import jsonp from '@/utils/jsonp'
import Util from '@lcs/utils'
import BxUtil from '@/utils/BxUtil'

const env = process.env
const VUE_APP_BASE_API = env.VUE_APP_BASE_API
const platform = Util.params.search.platForm || BxUtil.getQueryString('platform') || env.VUE_APP_INSURE_PLATFORM

export async function getJsApiConfig (is_agent = 1) {
  try {
    const res = await jsonp(
      `${VUE_APP_BASE_API}/api/api/getJsApiConfig`,
      {
        url: window.location.href.split('#')[0],
        is_agent,
        platform
      }
    )
    if (res.code === 0) {
      return res
    }
  } catch (err) {
    console.log(err)
  }
}
