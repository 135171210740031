import getHeader from './header'
import qs from 'qs'
import { removeWxInfo, removeToken } from '@/utils/auth'
import BxUtil from '@/utils/BxUtil'
const platform = BxUtil.getPlatform()
// eslint-disable-next-line no-unused-vars
const isNeedRefresh = (res) => {
  if (res && res.status === 200 && res.data.is_login === 0) {
    const url = res.config.url
    const isXht = /\/api\/xht/.test(url)
    const isLogin = /\/wxLogin/.test(url)
    const refreshCount = parseInt(window.localStorage.refreshCount) || 0
    if (!isXht && !isLogin && refreshCount < 1) {
      window.localStorage.refreshCount = refreshCount + 1
      removeWxInfo()
      removeToken()
      return true
    }
  }
  return false
}
const needResetCount = (res) => {
  if (res && res.status === 200 && res.data.is_login === 1) {
    const url = res.config.url
    const isXht = /\/api\/xht/.test(url)
    const refreshCount = parseInt(window.localStorage.refreshCount) || 0
    if (!isXht && refreshCount > 1) {
      return true
    }
  }
  return false
}
export default (http, options = {}) => {
  let interceptors = {
    request: {
      async success (config) {
        const headers = await getHeader({ url: config.url })
        const { method } = config
        // eslint-disable-next-line no-sparse-arrays
        const bxxx = decodeURIComponent((new RegExp('[?|&]' + 'bxxx' + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [, ''])[1].replace(/\+/g, '%20')) || ''
        // header赋值
        config.headers = Object.assign({}, config.headers, headers)
        if (method === 'post') { // post特殊处理
          config.data = qs.stringify(Object.assign({ platform: platform, bxxx: bxxx, link: window.location.href }, headers, config.data))
        } else {
          if (!config.params) config.params = {}
          config.params.platform = platform
          config.params.bxxx = bxxx
        }
        return config
      },
      error (e) {
        return Promise.reject(e)
      }
    },
    response: {
      success (res) {
        if (options.loginCheck) {
          if (isNeedRefresh(res) === true) {
            window.location.reload()
          } else {
            if (needResetCount(res)) {
              // 重置刷新次数
              window.localStorage.refreshCount = 0
            }
            return Promise.resolve(res.data)
          }
        } else {
          return Promise.resolve(res.data)
        }
      },
      error (e) {
        return Promise.reject(e)
      }
    }
  }
  if (options.interceptors) {
    interceptors = options.interceptors
  }

  Object.keys(interceptors).forEach(use => {
    http.interceptors[use].use(
      interceptors[use].success,
      interceptors[use].error
    )
  })

  return http
}
