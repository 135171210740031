import http from '@/utils/axios'
import jsonp from '@/utils/jsonp'
import { getQueryVariable, CBCPhone, signSms } from '@/utils/common'
// eslint-disable-next-line no-unused-vars
const getPlatform = () => {
  const ua = navigator.userAgent.toLowerCase()
  if (ua.match(/cs_/)) return '105'
  if (ua.match(/bx_/)) return '9'
  return getQueryVariable('platform') || '9'
}
/**
 * @description: 获取dy签名
 * @param {*} async
 * @return {*}
 * @Date Changed:
 */

export const getDouyinJsApiConfig = async () => {
  try {
    const url = process.env.VUE_APP_ENV === 'test' ? 'https://test-bx.liantech88.com' : 'https://bx.liantech88.com'
    const res = await jsonp(
      url + '/api/server/getDouyinJsApiConfig',
      {
        url: 'popularize.gfbxjj.cn',
        platform: 32
      }
    )
    if (res.code === 0) {
      return res
    } else {
      console.error('getDouyinJsApiConfig 错误', res.msg)
    }
  } catch (err) {
    console.log(err)
  }
}

export const getDouyinUserInfo = async (data) => {
  try {
    const res = await http.post('/api/server/getDouyinUserInfo', { ...data, platform: 32 })
    return res
  } catch (err) {
    console.error(err)
    return err
  }
}

export const getDouyinUserInfoByToken = async (data) => {
  try {
    const res = await http.post('/api/api/getDouyinUserInfoByToken', {
      platform: 32
    })
    return res
  } catch (err) {
    console.error(err)
    return err
  }
}

// 根据手机号获取用户 ,登录
export const login = async (data) => {
  try {
    const res = await http.post('/api/api/getUserInfoByPhone', data)
    return res
  } catch (err) {
    console.error(err)
    return err
  }
}

export const scanCodeLogin = async (data) => {
  try {
    const res = await http.get('/api/api/scanCodeLogin', {
      params: {
        ...data
      }
    })
    return res
  } catch (err) {
    return err.response.data
  }
}

// 验证码接口
export const sendCode = async data => {
  try {
    const platform = getPlatform()
    if (platform * 1 === 27 || platform * 1 === 38) {
      data.source = data.source || 10
    }
    const { phone, source = '', type = '' } = data
    const timestamp = new Date().getTime()
    const res = await http.post('/api/api/sendSmsNew', {
      phone: CBCPhone(phone),
      source,
      type
    },
    {
      headers: {
        timestamp,
        sign: signSms(phone, source, timestamp)
      }
    })
    return res
  } catch (err) {
    console.error(err)
    return err.response.data
  }
}
// 绑定手机号
export const bindPhone = async (data) => {
  try {
    const res = await http.post('/api/api/bindPhonePolicyOrder', data)
    return res
  } catch (err) {
    console.error(err)
    return err.response.data
  }
}
export const registerCrmTemp = async data => {
  try {
    const res = await http.post('/api/api/registerCrmTemp', data)
    return res
  } catch (err) {
    console.error(err)
    return err
  }
}
export const registerCrmDelay = async data => {
  try {
    const res = await http.post('/api/api/registerCrmDelay', data)
    return res
  } catch (err) {
    console.error(err)
    return err
  }
}
