/* eslint-disable space-before-function-paren */
import Vue from 'vue'
import VueRouter from 'vue-router'
const njLogin = () => import('@/views/NJ')
const ArticleDetail = () => import('@/views/articleDetail')
// const H5LivePage = () => import('@/views/h5LivePage')
const NewLive = () => import('@/views/h5LivePage/newLive.vue')
const HistoryLive = () => import('@/views/h5LivePage/historyLive.vue')
const ArticleList = () => import('@/views/articleList')
const PcLive = () => import('@/views/pcLive')
const bxPcLive = () => import('@/views/bxPcLive')
const Comment1 = () => import('@/views/articleDetail/comment')
const InsureAddWechat = () => import('@/views/insureAddWechat')
const QuestPage = () => import('@/views/questPage')
const RedCent = () => import('@/views/redCent/index.vue')
const RedCentOld = () => import('@/views/redCent/index1.vue')
const newRedCent = () => import('@/views/redCent/newIndex.vue')
const newRedCent1 = () => import('@/views/redCent/newIndex1.vue')
const client = () => import('@/views/client/index')
const clientList = () => import('@/views/client/clientList')
const clientDetail = () => import('@/views/client/clientDetail')
const testOutcome = () => import('@/views/client/testOutcome')
const behavior = () => import('@/views/client/behavior')
const policyRecord = () => import('@/views/client/policyRecord.vue')
const clientPaint = () => import('@/views/client/clientPaint.vue')
const InsurancePlan = () => import('@/views/insurancePlan/index')
const InsurancePlanList = () => import('@/views/insurancePlan/planList')
const InsuranceMakePlan = () => import('@/views/insurancePlan/makePlan')
const InsurancePlanDetail = () => import('@/views/insurancePlan/planDetail')
const InsurancePage = () => import('@/views/insurancePage')
const crmMsgConfig = () => import('@/views/crmMsgConfig/index')
const luckyCircle = () => import('@/views/luckyCircle/luckyCircle.vue')
const prizelist = () => import('@/views/luckyCircle/prizelist')
const szActivity = () => import('@/views/szActivity/index')
const question = () => import('@/views/question/index')
const productCompare = () => import('@/views/client/productCompare')
const messageCenter = () => import('@/views/home/messageCenter')
const Home = () => import('@/views/home/index')
const act082501 = () => import('@/views/act082501/index')
const mediaPage = () => import('@/views/mediaPage/index')
const liveReserve = () => import('@/views/liveReserve/index')
const bindPhone = () => import('@/views/luckyCircle/bindPhone.vue')
const questionCollect = () => import('@/views/questionCollect/index.vue')
const insurancePolicy = () => import('@/views/insurancePolicy/index.vue')
const oldInsurancePolicyPage = () =>
  import('@/views/insurancePolicy/policy.vue')
const insuranceDetails = () => import('@/views/insurancePolicy/details.vue')
const policyDetails = () => import('@/views/insurancePolicy/policyDetail.vue')
const actSZ = () => import('@/views/actSZ/index.vue')
const actGetWelfare = () => import('@/views/actGetWelfare/index.vue')
const liveStaffOrder = () => import('@/views/liveStaffOrder/index.vue')
const employeesTool = () => import('@/views/employeesTool/index.vue')
const liveVideo = () => import('@/views/employeesTool/liveVideo.vue')
const accessmMessage = () => import('@/views/employeesTool/messageCenter.vue')
const liveVideoMesCenter = () =>
  import('@/views/employeesTool/liveVideoMesCenter.vue')
const businessCard = () => import('@/views/businessCard/index.vue')
const childInsurance = () => import('@/views/childInsurance/index.vue')
const actCenter = () => import('@/views/actCenter/index.vue')
const promotePage = () => import('@/views/promotePage/index.vue')
const mobileLogin = () => import('@/views/mobileLogin/index.vue')
const bxMedia = () => import('@/views/bxMedia/index.vue')
const qychart = () => import('@/views/qychart/index')
const actCalcInsured = () => import('@/views/actCalcInsured/index.vue')
const selectPlan = () => import('@/views/actCalcInsured/selectPlan.vue')
const insureDetail = () => import('@/views/actCalcInsured/insureDetail.vue')
const wealthCourse = () => import('@/views/wealthCourse/wealthCourse.vue')
const video = () => import('@/views/wealthCourse/video.vue')
const appDownload = () => import('@/views/appDownload/index.vue')
const download = () => import('@/views/supportWeixinQQ/download.vue')
const productLib = () => import('@/views/productLib/productLib.vue')
const proLibMes = () => import('@/views/productLib/proLibMesCenter.vue')
const productCover = () => import('@/views/productCover/productCover.vue')
const yrProductCover = () => import('@/views/yrProductCover/yrProductCover.vue')
const productAnalysis = () =>
  import('@/views/productAnalysis/productAnalysis.vue')
const analysisDetail = () =>
  import('@/views/productAnalysis/analysisDetail.vue')
const actNewWelfare = () => import('@/views/actNewWelfare/index.vue')
const community = () => import('@/views/community/index.vue')
const clockIn = () => import('@/views/community/clockIn.vue')
const getCertificate = () => import('@/views/community/getCertificate.vue')
const insurance = () => import('@/views/insurance/index.vue')
const insuranceList = () => import('@/views/insurance/pages/list.vue')
const GeneratePlan = () => import('@/views/insurance/pages/generatePlan.vue')
// const planDetail = () => import('@/views/insurance/pages/planDetail/index')
const planDetail = () =>
  import('@/views/insurance/pages/planDetail/planDetailNew')
const insured = () => import('@/views/insurance/pages/insured.vue')
const act20122801 = () => import('@/views/act20122801/index.vue')
const act21011901 = () => import('@/views/act/2101/act21011901/index.vue')
const act21022401 = () => import('@/views/act/2102/act21022401/index.vue')
const act21022402 = () => import('@/views/act/2102/act21022402/index.vue')
const act21041201 = () => import('@/views/act/2104/act21041201/index.vue')
const logout = () => import('@/views/logout/index.vue')
const DailyInsurance = () => import('@/views/dailyInsurance/index.vue')
const channel = () => import('@/views/channel/index.vue')
const getCorpQrcode = () => import('@/views/act/2109/act21092701/index.vue')
const authTest = () => import('@/views/authTest/index.vue')
// 5周年活动
const fiveYearAct = () => import('@/views/fiveYearAct/index')

// 内容活动页面-搜索页面
const insuranceSearch = () => import('@/views/insurancePage/search.vue')

// 视频直播页面-预约页面
const liveSubscribe = () => import('@/views/h5LivePage/liveSubscribe.vue')

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}
const routes = [
  {
    path: '/njLogin',
    name: 'njLogin',
    meta: {
      dynamicTitle: true
    },
    component: njLogin
  },
  {
    path: '/qychart',
    name: 'Qychart',
    meta: {
      dynamicTitle: true
    },
    component: qychart
  },
  {
    path: '/articleDetail',
    name: 'ArticleDetail',
    meta: {
      dynamicTitle: true
    },
    component: ArticleDetail
  },
  {
    path: '/Comment',
    name: 'Comment',
    meta: {
      dynamicTitle: true
    },
    component: Comment1
  },
  {
    path: '/articleList',
    name: 'ArticleList',
    meta: {
      savedPosition: true,
      dynamicTitle: true
    },
    component: ArticleList
  },
  {
    path: '/h5LivePage',
    name: 'H5LivePage',
    meta: {
      dynamicTitle: true
    },
    component: NewLive
  },
  {
    path: '/hisLive',
    name: 'hisLive',
    meta: {
      dynamicTitle: true
    },
    component: HistoryLive
  },
  {
    path: '/pcLive',
    name: 'PcLive',
    meta: {
      dynamicTitle: true
    },
    component: PcLive
  },
  {
    path: '/bxPcLive',
    name: 'bxPcLive',
    meta: {
      dynamicTitle: true
    },
    component: bxPcLive
  },
  {
    path: '/insureAddWechat',
    name: 'InsureAddWechat',
    meta: {
      dynamicTitle: true
    },
    component: InsureAddWechat
  },
  {
    path: '/questPage',
    name: 'questPage',
    meta: {
      dynamicTitle: true
    },
    component: QuestPage
  },
  {
    path: '/redCent',
    name: 'redCent',
    meta: {
      dynamicTitle: true
    },
    component: RedCent
  },
  {
    path: '/redCentOld',
    name: 'redCentOld',
    meta: {
      dynamicTitle: true
    },
    component: RedCentOld
  },
  {
    path: '/newRedCent',
    name: 'newRedCent',
    meta: {
      dynamicTitle: true
    },
    component: newRedCent
  },
  {
    path: '/newRedCent1',
    name: 'newRedCent1',
    meta: {
      dynamicTitle: true
    },
    component: newRedCent1
  },
  {
    path: '/client',
    name: 'Client',
    redirect: '/client/clientList',
    meta: {
      dynamicTitle: true
    },
    component: client,
    children: [
      {
        path: 'clientList',
        name: 'clientList',
        meta: {
          showBack: true
        },
        component: clientList
      },
      {
        path: 'clientDetail',
        name: 'clientDetail',
        meta: {
          showBack: true
        },
        component: clientDetail
      },
      {
        path: 'testOutcome',
        name: 'testOutcome',
        meta: {
          showBack: true,
          step: -2
        },
        component: testOutcome
      },
      {
        path: 'behavior',
        name: 'behavior',
        meta: {
          showBack: true
        },
        component: behavior
      },
      {
        path: 'policy',
        meta: {
          showBack: true
        },
        component: policyRecord
      },
      {
        path: 'clientPaint',
        meta: {
          showBack: true
        },
        component: clientPaint
      },
      {
        path: 'question',
        meta: {
          showBack: true
        },
        component: question
      },
      {
        path: 'productCompare',
        meta: {
          showBack: true
        },
        component: productCompare
      }
    ]
  },
  {
    path: '/insuranfe-plan',
    name: 'InsuranfePlan',
    meta: {
      dynamicTitle: true
    },
    redirect: '/insuranfe-plan/plan-list',
    component: InsurancePlan,
    children: [
      {
        path: 'plan-list',
        name: 'PlanList',
        meta: {
          showBack: true,
          dynamicTitle: true
        },
        component: InsurancePlanList
      },
      {
        path: 'make-plan',
        name: 'MakePlan',
        meta: {
          showBack: true,
          dynamicTitle: true
        },
        component: InsuranceMakePlan
      },
      {
        path: 'plan-detail',
        name: 'PlanDetail',
        meta: {
          showBack: true,
          dynamicTitle: true
        },
        component: InsurancePlanDetail
      }
    ]
  },
  {
    path: '/insurancePage',
    name: 'insurancePage',
    meta: {
      dynamicTitle: true
    },
    component: InsurancePage
  },
  {
    path: '/insuranceSearch',
    name: 'insuranceSearch',
    component: insuranceSearch
  },
  {
    path: '/crmMsg',
    name: 'CrmMsgConfig',
    meta: {
      dynamicTitle: true
    },
    component: crmMsgConfig
  },
  {
    path: '/luckyCircle',
    name: 'luckyCircle',
    meta: {
      dynamicTitle: true
    },
    component: luckyCircle
  },
  {
    path: '/prizelist',
    name: 'prizelist',
    meta: {
      dynamicTitle: true
    },
    component: prizelist
  },
  {
    path: '/szActivity',
    name: 'szActivity',
    meta: {
      dynamicTitle: true
    },
    component: szActivity
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      dynamicTitle: true
    },
    component: Home
  },
  {
    path: '/messageCenter',
    name: 'messageCenter',
    meta: {
      dynamicTitle: true,
      showBack: true
    },
    component: messageCenter
  },
  {
    path: '/act082501',
    name: 'act082501',
    meta: {
      dynamicTitle: true
    },
    component: act082501
  },
  {
    path: '/mediaPage',
    name: 'mediaPage',
    meta: {
      dynamicTitle: true
    },
    component: mediaPage
  },
  {
    path: '/liveReserve',
    name: 'liveReserve',
    meta: {
      dynamicTitle: true
    },
    component: liveReserve
  },
  {
    path: '/bindPhone',
    name: 'bindPhone',
    meta: {
      dynamicTitle: true
    },
    component: bindPhone
  },
  {
    path: '/questionCollect',
    name: 'questionCollect',
    meta: {
      dynamicTitle: true
    },
    component: questionCollect
  },
  {
    path: '/insurancePolicy',
    name: 'insurancePolicy',
    meta: {
      dynamicTitle: true
    },
    component: insurancePolicy
  },
  {
    path: '/policy',
    name: 'Policy',
    meta: {
      dynamicTitle: true
    },
    component: oldInsurancePolicyPage
  },
  {
    path: '/policyDetails',
    name: 'policyDetails',
    meta: {
      dynamicTitle: true,
      showBack: true
    },
    component: policyDetails
  },
  {
    path: '/insuranceDetails',
    name: 'insuranceDetails',
    meta: {
      dynamicTitle: true,
      showBack: true
    },
    component: insuranceDetails
  },
  {
    path: '/actSZ',
    name: 'actSZ',
    meta: {
      dynamicTitle: true
    },
    component: actSZ
  },
  {
    path: '/liveStaffOrder',
    name: 'liveStaffOrder',
    meta: {
      dynamicTitle: true
    },
    component: liveStaffOrder
  },
  {
    path: '/actGetWelfare',
    name: 'actGetWelfare',
    meta: {
      dynamicTitle: true
    },
    component: actGetWelfare
  },
  {
    path: '/childInsurance',
    name: 'childInsurance',
    meta: {
      dynamicTitle: true
    },
    component: childInsurance
  },
  {
    path: '/employees-tool',
    name: 'employeesTool',
    meta: {},
    component: employeesTool
  },
  {
    path: '/access-message',
    name: 'accessmMessage',
    meta: {
      title: '专项链消息中心'
    },
    component: accessmMessage
  },
  {
    path: '/liveVideo',
    name: 'liveVideo',
    meta: {
      // dynamicTitle: true
    },
    component: liveVideo
  },
  {
    path: '/liveMes',
    name: 'liveMes',
    meta: {
      title: '视频直播消息中心'
    },
    component: liveVideoMesCenter
  },
  {
    path: '/business-card',
    name: 'businessCard',
    meta: {},
    component: businessCard
  },
  {
    path: '/actCenter',
    name: 'actCenter',
    meta: {
      dynamicTitle: true
    },
    component: actCenter
  },
  {
    path: '/promotePage',
    name: 'promotePage',
    meta: {
      dynamicTitle: true
    },
    component: promotePage
  },
  {
    path: '/bxMedia',
    name: 'bxMedia',
    meta: {
      dynamicTitle: true
    },
    component: bxMedia
  },
  {
    path: '/actCalcInsured',
    name: 'actCalcInsured',
    meta: {
      dynamicTitle: true
    },
    component: actCalcInsured
  },
  {
    path: '/selectPlan',
    name: 'selectPlan',
    meta: {
      dynamicTitle: true
    },
    component: selectPlan
  },
  {
    path: '/insureDetail',
    name: 'insureDetail',
    meta: {
      dynamicTitle: true
    },
    component: insureDetail
  },
  {
    path: '/mobileLogin',
    name: 'mobileLogin',
    meta: {
      dynamicTitle: true
    },
    component: mobileLogin
  },
  {
    path: '/wealthCourse',
    name: 'wealthCourse',
    meta: {
      dynamicTitle: true
    },
    component: wealthCourse
  },
  {
    path: '/video',
    name: 'video',
    meta: {
      dynamicTitle: true
    },
    component: video
  },
  {
    path: '/appDownload',
    name: 'appDownload',
    meta: {
      dynamicTitle: true
    },
    component: appDownload
  },
  {
    path: '/download',
    name: 'download',
    meta: {
      dynamicTitle: true
    },
    component: download
  },
  {
    path: '/productLib',
    name: 'productLib',
    meta: {
      dynamicTitle: true
    },
    component: productLib,
    children: [
      {
        path: 'proLibMes',
        name: 'proLibMes',
        meta: {
          dynamicTitle: true
        },
        component: proLibMes
      }
    ]
  },
  {
    path: '/productCover',
    name: 'productCover',
    meta: {
      dynamicTitle: true
    },
    component: productCover
  },
  {
    path: '/yrProductCover',
    name: 'yrProductCover',
    meta: {
      dynamicTitle: true
    },
    component: yrProductCover
  },
  {
    path: '/insurance',
    name: 'insurance',
    meta: {
      dynamicTitle: true
    },
    component: insurance,
    redirect: '/insurance/list',
    children: [
      {
        path: 'list',
        name: 'list',
        component: insuranceList
      },
      {
        path: 'plan',
        name: 'plan',
        component: GeneratePlan
      },
      {
        path: 'detail',
        name: 'detail',
        component: planDetail
      },
      {
        path: 'insured',
        name: 'insured',
        component: insured
      }
    ]
  },
  {
    path: '/actNewWelfare',
    name: 'actNewWelfare',
    meta: {
      dynamicTitle: true
    },
    component: actNewWelfare
  },
  {
    path: '/product-analysis',
    name: 'product-analysis',
    meta: {
      dynamicTitle: true
    },
    component: productAnalysis,
    children: [
      {
        path: 'pro-analysis-detail',
        name: 'pro-analysis-detail',
        meta: {
          // showBack: true
        },
        component: analysisDetail
      }
    ]
  },
  {
    path: '/community',
    name: 'community',
    redirect: '/community/clockIn',
    meta: {
      dynamicTitle: true
    },
    component: community,
    children: [
      {
        path: 'clockIn',
        name: 'clockIn',
        meta: {
          dynamicTitle: true
        },
        component: clockIn
      },
      {
        path: 'getCertificate',
        name: 'getCertificate',
        meta: {
          dynamicTitle: true
        },
        component: getCertificate
      }
    ]
  },
  {
    path: '/act20122801',
    name: 'act20122801',
    meta: {
      dynamicTitle: true
    },
    component: act20122801
  },
  {
    path: '/act21011901',
    meta: {
      dynamicTitle: true
    },
    component: act21011901
  },
  {
    path: '/act21022401',
    meta: {
      title: '咨询代金券免费领'
    },
    component: act21022401
  },
  {
    path: '/act21022402',
    meta: {
      title: '重疾保障咨询免费领'
    },
    component: act21022402
  },
  {
    path: '/logout',
    meta: {
      dynamicTitle: true
    },
    component: logout
  },
  {
    path: '/act21041201',
    meta: {
      dynamicTitle: '保险内购福利'
    },
    component: act21041201
  },
  {
    path: '/dailyInsurance',
    name: 'DailyInsurance',
    meta: {
      dynamicTitle: true
    },
    component: DailyInsurance
  },
  {
    path: '/channel',
    name: 'channel',
    meta: {
      dynamicTitle: true
    },
    component: channel
  },
  {
    path: '/getCorpQrcode',
    name: 'getCorpQrcode',
    meta: {
      dynamicTitle: true
    },
    component: getCorpQrcode
  },
  {
    path: '/authTest',
    name: 'authTest',
    meta: {
      dynamicTitle: true
    },
    component: authTest
  },
  {
    path: '/fiveYearAct',
    name: 'fiveYearAct',
    component: fiveYearAct
  },
  {
    path: '/liveSubscribe',
    name: 'liveSubscribe',
    component: liveSubscribe
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.meta && to.meta.savedPosition) {
      return savedPosition
    }
  }
})

router.beforeEach((to, from, next) => {
  if (!to.meta.dynamicTitle) {
    document.title = '\u200E'
  }
  next()
})
// router.onError((error) => {
//   // 防IE缓存导致找不到js文件
//   const pattern = /Loading chunk \d+ failed/g
//   const isChunkLoadFailed = error.message.match(pattern)
//   if (isChunkLoadFailed) {
//     location.reload()
//   console.log(1)
//   }
// })
export default router
