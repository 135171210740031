// eslint-disable-next-line no-unused-vars
import { getToken } from '@/utils/auth'
/* eslint-disable camelcase */
import cookieUtils from '@lcs/utils/dist/cookieUtils'
import { getCookies } from '@/utils/BxUtil'

const headers = {
  DEFAULT: {
    'x-fr': 'lcs_web',
    deviceid: (() => {
      let bx_deviceId = ''
      if (cookieUtils.getCookie('deviceId')) {
        bx_deviceId = cookieUtils.getCookie('deviceId')
      } else {
        getCookies('deviceId').then(res => {
          bx_deviceId = res
        })
      }
      return bx_deviceId
    })()
  }

}
export default (key) => (headers[key] || headers.DEFAULT)
