const state = {
  activity: {}
}
const mutations = {
  SET_ACTIVITY: (state, activity) => { state.activity = activity }
}
export default {
  namespaced: true,
  state,
  mutations
}
