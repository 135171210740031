import http from '@/utils/axios'
import newhttp from '@/utils/http'
// import axios from 'axios'
// const qs = require('qs')
// const { protocol } = window.location
// const env = process.env.VUE_APP_ENV
// const RHurl = env === 'test' ? `${protocol}//test-gateway.jinyi999.cn` : `${protocol}//gateway.jinyi999.cn`

// 微信登录
export const wxLogin = async params => {
  try {
    const res = await newhttp.get('/api/server/getWxUserInfo', params, {
      disableTip: true
    })
    return res
  } catch (err) {
    return err.response.data
  }
}
// 企业微信登录 - 获取用户信息
export const qywxLogin = async params => {
  try {
    const res = await http.get('/api/qywx/login', {
      params: {
        ...params
      }
    })
    return res
  } catch (err) {
    return err.response.data
  }
}
// 微信accessToken登录
export const wxAccessLogin = async params => {
  try {
    const res = await http.get('/api/api/getWxUserInfo', {
      params: {
        ...params
      }
    })
    return res
  } catch (err) {
    return err.response.data
  }
}
// 微信accessToken登录 展业助手app
export const wxAccessLoginZy = async params => {
  try {
    const res = await newhttp.get('/api/zy-api/getWxUserInfo', params)
    return res
  } catch (err) {
    return err.response.data
  }
}
// 融汇微信登录
export const RHwxLogin = async params => {
  return { code: 1, data: { blackUser: 0, channel: '', createTime: 0, gender: 0, headImage: '', lastLoginTime: 0, new: false, nickname: '', nicknameIllegalType: 0, roomToken: '', serverId: 0, token: '', tradeAccount: [], unionid: '', updateTime: 0, userType: 0, username: '', wechatId: '', wxNickname: '' }, msg: '' }
}

// 汇融微信登录同步保险用户信息
export const syncBxUserInfo = async data => {
  try {
    const res = await http.get('/api/server/syncBxUserInfo', {
      params: {
        ...data
      }
    })
    return res
  } catch (err) {
    return err.response.data
  }
}
// 微信号绑定手机
export const bindPhone = async params => {
  try {
    const res = await http.post('/api/api/wxRegister', {
      ...params
    })
    return res
  } catch (err) {
    return err.response.data
  }
}
// 企业微信获取外部联系人信息
export const externalUserInfo = async data => {
  try {
    const res = await http.get('/api/qywx/externalUserInfo', {
      params: {
        ...data
      }
    })
    return res
  } catch (err) {
    return err.response.data
  }
}
// 获取appid
export const getWxConfig = async params => {
  try {
    const res = await http.post('/api/api/getWxConfig', {
      ...params
    })
    return res
  } catch (err) {
    return err.response.data
  }
}
