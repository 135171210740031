
import { Toast } from 'vant'
import { planCalPremium, getPlanList, delPlan } from '@/api/insurance.js'

import dateUtils from '@lcs/utils/dist/dateUtils'

const DEFAULT_USER_INFO = {
  age: '',
  gender: 'm',
  plan_insured_name: '',
  plan_name: ''
}
const state = {
  currentUserIndex: 0,
  userList: [],
  isEdit: false // 判断是否是编辑产品
}

const mutations = {
  INIT_USER_LIST (state, data) {
    state.userList = data
  },
  UPDATA_ALL_PRODUCR (state, productList) {
    state.userList[state.currentUserIndex].productList = productList
  },
  // 更新当前操作的user 下标
  SET_USER_INDEX (state, index) {
    state.currentUserIndex = index
  },
  // 新增产品
  ADD_PRODUCTLIST (state, productListItem) {
    state.userList[state.currentUserIndex].productList.push(productListItem)
    console.log(state.userList[state.currentUserIndex].productList, 'state.userList[state.currentUserIndex].productList')
  },
  // 删除产品
  DELETE_PRODUCTLIST_BY_ID (state, id) {
    const index = state.userList[state.currentUserIndex].productList.findIndex(item => item.product_id === id)
    state.userList[state.currentUserIndex].productList.splice(index, 1)
  },
  // 更新产品
  UPDATA_PRODUCTLIST_BY_ID (state, productListItem) {
    if (!state.userList.length) {
      state.userList[state.currentUserIndex].productList.push(productListItem)
    } else {
      const index = state.userList[state.currentUserIndex].productList.findIndex(item => item.product_id === productListItem.product_id)
      state.userList[state.currentUserIndex].productList.splice(index, 1, productListItem)
    }
  },

  SET_USERID (state, val) {
    state.userId = val
  },
  // 新增
  ADD_USER_LIST (state, userItem) {
    state.userList.push(userItem)
  },
  // 删除
  DELETE_USER_LIST (state, index) {
    state.userList.splice(index, 1)
  },
  // 更新
  UPDATA_USER_LIST (state, userItem) {
    state.userList.splice(state.currentUserIndex, 1, userItem)
  },
  SET_USER_LIST (state, val) {
    state.userList = val
  },
  SET_IS_EDIT (state, val) {
    state.isEdit = val
  }

}

const actions = {
  // 初始化数据，防止页面不刷新，导致脏数据
  initData ({ commit }) {
    commit('SET_USER_INFO', DEFAULT_USER_INFO)
    commit('SET_PRODUCT_INFO', {})
    commit('SET_PRODUCT_LIST', [])
    commit('SET_MONEY', '')
    commit('SET_PLAN_ID', null)
    commit('SET_PROTECT_INFO', {})
  },
  async getPlanList ({ commit }, data) {
    try {
      const res = await getPlanList({
        uid: data.uid,
        open_id: data.openid,
        union_id: data.unionid
      })
      if (res.code === 0) {
        res.data && res.data.data && res.data.data.forEach(item => {
          item.c_time = dateUtils.format(new Date(item.c_time.replace(/-/g, '/')))
        })
        console.log('res.data.data: ', res.data.data)
        commit('SET_PLAN_LIST', res.data.data || [])
        return { code: 0 }
      } else {
        return {
          code: res.code,
          error: res.msg
        }
      }
    } catch (error) {
      return {
        code: 1, error
      }
    }
  },

  async delPlan ({ commit }, data) {
    console.log('data: ', data)
    if (!data.id) return
    return delPlan({ plan_id: data.id, open_id: data.openid, union_id: data.unionid })
  },
  setProductInfo ({ commit }, option) {
    commit('SET_PRODUCT_INFO', option)
  },
  async setMoney ({ commit }, { age, gender, productList = [] }) {
    try {
      const products = []
      const deepCopyProductList = JSON.parse(JSON.stringify(productList))
      deepCopyProductList.forEach(item => {
        item.errMsg = []
        const additionsItem = {}
        for (const key in item.addition) {
          // 附加险依赖的主线值不为0-0的时候才将该附加险传给接口
          if (item.configs[key].value !== '0-0') {
            const obj = item.addition[key]
            additionsItem[key] = {
              product_id: obj.product_id,
              ...obj.configs
            }
          }
        }
        const productItem = {
          product_id: item.product_id,
          addition: additionsItem,
          ...item.configs
        }
        products.push(productItem)
      })
      console.log(JSON.stringify({ birthday: age, sex: gender === '男' ? '1' : '2', products }))
      const res = await planCalPremium({ birthday: age, sex: gender === '男' ? '1' : '2', products })
      if (res.code === 0) {
        for (const item of deepCopyProductList) {
          for (const resItem of res.data.products) {
            if (item.product_id === resItem.product_id) {
              // console.error(item, 'item')
              // 需要输入保额的产品，再修改完年龄后，修改保额
              if (resItem.sum_insured) {
                item.configs.sum_insured.label = item.configs.sum_insured.value = resItem.sum_insured
              }
              item.errMsg = resItem.error_message
              item.total_premium = resItem.total_premium
              break
            }
          }
        }
        commit('UPDATA_ALL_PRODUCR', deepCopyProductList)
      } else {
        Toast(res.msg)
      }
      return {
        code: res.code,
        msg: res.msg
      }
    } catch (error) {
      console.log('🚀 ~ file: insurance.js ~ line 202 ~ error', error)

      return {
        code: -1,
        msg: error,
        data: {
          product_data: []
        }
      }
    }
  },
  setProductList ({ commit }, { type, arg }) {
    switch (type) {
      case 'add':
        commit('ADD_PRODUCTLIST', arg)
        break
      case 'delete':
        commit('DELETE_PRODUCTLIST_BY_ID', arg)
        break
      case 'updata':
        commit('UPDATA_PRODUCTLIST_BY_ID', arg)
        break
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
