// eslint-disable-next-line no-unused-vars
import { Toast, Dialog } from 'vant'
import BxBridge from '@/utils/BxBridge'
import BxUtil from '@/utils/BxUtil'
const isBxApp = BxUtil.bxApp && BxUtil.bxApp.isQJBX
const login = (msg) => {
  Dialog.alert({
    title: '提示',
    message: msg || '登录失效，请重新登录'
  }).then(() => {
    if (isBxApp) {
      BxBridge.callByJS({
        name: 'NativeUniversalJump',
        param: {
          rname: 'login'
        }
      })
    }
  })
}
export default {
  '-1001': login,
  '-701': login,
  '-703': login,
  401: login
}
