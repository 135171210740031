/* eslint-disable camelcase */
import originJspon from 'jsonp'

import getHeader from '@/utils/axios/config/header'

export default async function jsonp (url, data, option) {
  const header = await getHeader()
  // 加时间戳防止缓存
  const params = Object.assign({}, header, data, { _: Date.now() })
  const defaultOption = {
    prefix: 'LcsJsonp',
    timeout: 8000
  }
  option = Object.assign(defaultOption, option)

  // url 拼接
  url += (url.indexOf('?') < 0 ? '?' : '&') + param(params)
  return new Promise((resolve, reject) => {
    originJspon(url, option, (err, data) => {
      if (!err) {
        resolve(data)
      } else {
        reject(err)
      }
    })
  })
}

function param (data) {
  let url = ''
  for (var k in data) {
    const value = data[k] !== undefined ? data[k] : ''
    url += `&${k}=${encodeURIComponent(value)}`
  }
  return url ? url.substring(1) : ''
}
