/**
 * Created by fangqiwei on 2020/09/21
 */
import BxUtil from '@/utils/BxUtil'
import BxBridge from '@/utils/BxBridge'

class LinkJump {
  // eslint-disable-next-line space-before-function-paren
  constructor(option) {
    /**
     *
     * @type {{router: object, isShare: string}}
     * router:router就是将整个VueRouter传进来
     * isShare:是否显示分享按钮（后期支持）
     */
    const defaultOption = {
      router: '',
      isShare: '0'
    }
    this.option = Object.assign({}, defaultOption, option || {})
    // $.extend(this.option, defaultOption, option || {})
    this._jump()
  }

  _jump () {
    // 判断是否在启见保险app
    const isQJBX = BxUtil.bxApp

    const _this = this
    const router = _this.option.router
    let href = ''
    router.beforeEach((to, from, next) => {
      if (from.fullPath === '/') {
        next()
      } else if (isQJBX) {
        // 将href匹配为正确路径
        href = window.location.href.replace(from.fullPath, to.fullPath)
        const title = to.query.title
        console.error('title', title, 'href', href)
        BxBridge.callByJS({
          name: 'NativeUniversalJump',
          param: {
            rname: 'link',
            params: {
              r_id: href,
              title
            }
          }
        })
      } else {
        next()
      }
    })
  }
}

export default LinkJump
