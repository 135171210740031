const requireVconsole = async () => import(/* webpackChunkName:"npm.vconsole" */'vconsole')
export default async function loadVconsole () {
  if ((process.env.VUE_APP_ENV !== 'production' || window.location.href.indexOf('vconsole') !== -1) && window.location.href.indexOf('noVconsole') === -1) {
    console.log(process.env.VUE_APP_ENV, 'process.env.VUE_APP_ENV ')
    const VConsole = await requireVconsole()
    const constructor = VConsole.default
    console.log('🚀 ~ file: main.js ~ line 20 ~ VConsole', VConsole.default)
    // eslint-disable-next-line no-unused-vars
    const vConsole = new constructor({ defaultPlugins: ['system', 'network', 'element', 'storage'] })
  }
}
