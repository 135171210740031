import { getToken } from '@/utils/auth'
/* eslint-disable camelcase */
import cookieUtils from '@lcs/utils/dist/cookieUtils'
import { getCookies } from '@/utils/BxUtil'

export default async (option = {}) => {
  // const fr = await getCookies('fr') || 'lcs_web'
  const fr = 'lcs_web'
  const bx_deviceId = cookieUtils.getCookie('deviceId') || await getCookies('deviceId') || ''
  const accessToken = getToken() || await getCookies('access-token')
  const header = {
    'access-token': accessToken,
    'x-fr': fr,
    deviceid: bx_deviceId
  }
  return header
}
