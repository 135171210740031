const config = {
  test: { // 24环境
    baseApi: '//test-bx.liantech88.com',
    lcsApi: '//test-api.sylapp.cn',
    saApi: {
      name: 'sa',
      web_url: '',
      server_url: 'https://test-sensors-api.baidao.com/sa?project=lcs_qijianbx_test&token=oiuy.w98',
      heatmap: {},
      use_app_track: true,
      show_log: true
    }
  },
  emu: { // 235环境
    baseApi: '//emu-bx.liantech88.com',
    lcsApi: '//emu-api.sylapp.cn',
    saApi: {
      name: 'sa',
      web_url: '',
      server_url: 'https://jc-sensors.baidao.com/sa?project=lcs_qijianbx&token=uewq.873',
      heatmap: {},
      use_app_track: true,
      show_log: true
    }
  },
  production: { // 生产环境
    baseApi: '//bx.liantech88.com',
    lcsApi: '//api.sylapp.cn',
    saApi: {
      name: 'sa',
      web_url: '',
      server_url: 'https://jc-sensors.baidao.com/sa?project=lcs_qijianbx&token=uewq.873',
      heatmap: {},
      use_app_track: true,
      show_log: false
    }
  }
}
let envConfig = config[process.env.VUE_APP_ENV || 'test']
if (process.env.NODE_ENV === 'development') { // 开发环境特殊配置
  envConfig = Object.assign({}, envConfig, {
    baseApi: '/lcs-api', // 开发环境可走本地代理，也可注释掉不走代理
    lcsApi: '/lcs-sina-api'
  })
}
export default envConfig
