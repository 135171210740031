<template>
  <div id="app">
    <img v-if="showBack" class="history-back"
      src="//fs.sylapp.cn/upload/20200904/2020090410190079d3e9a6c09c0b9b387c282a245b4ea75d79.png"
      @click="historyBack">
    <keep-alive :include="include">
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
import Util from '@lcs/utils'

export default {
  data () {
    return {
      include: ['ArticleList', 'PcLive'],
      href: '',
      showBack: false,
      step: -1
    }
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.meta.showBack && Util.thirdApp.isWeixin) {
          const { jumpTo, step } = val.meta
          if (window.history.length > 1) this.showBack = true
          this.href = jumpTo || ''
          this.step = step || -1
        } else this.showBack = false
      },
      deep: true
    }
  },
  created () {
    window.lib.flexible.refreshRem()
    this.$nextTick(() => {
      setTimeout(() => {
        // 以下代码思路来源网络。同时代码放在 body 标签开头位置效果最佳
        const $dom = document.createElement('div')
        $dom.style = 'font-size: 1000px'
        document.body.appendChild($dom)
        // 计算出放大后的字体
        const scaledFontSize = Math.ceil(Number(window.getComputedStyle($dom, null).getPropertyValue('font-size').replace('px', '')))
        document.body.appendChild($dom)
        // 计算原字体和放大后字体的比例
        const scaleFactor = 1000 / scaledFontSize
        // 取 html 元素的字体大小
        const originRootFontSize = Math.ceil(Number(window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size').replace('px', '')))
        console.error(Math.ceil(Number(originRootFontSize * scaleFactor * scaleFactor)) + 'px')
        // 由于设置 font-size 后实际会变大，故 font-size 需设置为更小一级
        document.documentElement.style.fontSize = Math.ceil(Number(originRootFontSize * scaleFactor * scaleFactor)) + 'px'
      }, 500)
    })
  },
  methods: {
    historyBack () {
      if (!this.href || this.step) window.history.back(this.step)
      else this.$router.push({ path: this.href })
    }
  }
}
</script>
<style lang="less">
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "PingFang SC", Avenir, Helvetica, Arial, sans-serif;
}

#app {
  max-width: 750px;
  margin: 0 auto;
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.col-xs,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-6 {
  padding-left: 0;
  padding-right: 0;
}

.history-back {
  z-index: 200;
  position: fixed;
  left: 0;
  bottom: 25%;
  width: 70px;
}
</style>
