
// import http from '@/utils/axios'
import http from '@/utils/http'

// 新的计划书接口

// 计划书产品列表
export const planProducts = async (data) => {
  try {
    const res = await http.get('/api/api/planProducts', data)
    return res
  } catch (err) {
    console.error(err)
    return err
  }
}
export const planProductDuty = async (data) => {
  try {
    const res = await http.get('/api/api/planProductDuty', data)
    return res
  } catch (err) {
    console.error(err)
    return err
  }
}

// 根据产品id和被保人信息获取 保障责任选项
export const productConfigs = async (data) => {
  try {
    const res = await http.post('/api/api/productConfigs', data, {
      disableTip: true
    })
    return res
  } catch (err) {
    console.error(err)
    return err
  }
}

// 计算保费 // 可多个产品
export const planCalPremium = async (data) => {
  try {
    const res = await http.post('/api/api/planCalPremium', data)
    return res
  } catch (err) {
    console.error(err)
    return err
  }
}
// 保险计划书详情接口 -- 编辑
export async function planEditInfo (data) {
  try {
    const res = await http.post('/api/api/planEditInfo', data)
    return res
  } catch (error) {
    console.log('planEditInfo -> error', error)
  }
}
// 保险计划书 封面
export async function planTheme (data) {
  try {
    const res = await http.post('/api/api/planTheme', data)
    return res
  } catch (error) {
    console.log('planTheme -> error', error)
  }
}
// 添加/编辑计划书
export async function crtPlan (data) {
  try {
    const res = await http.post('/api/api/crtPlan', data)
    return res
  } catch (error) {
    console.log('crtPlan -> error', error)
  }
}
// 保险计划书列表
export async function getPlanList (data) {
  try {
    const res = await http.post('/api/api/newPlanList', data)
    return res
  } catch (error) {
    console.log('getPlanList -> error', error)
  }
}

// 保险计划书删除
export async function delPlan (data) {
  try {
    const res = await http.post('/api/api/newPlanDelete', data)
    return res
  } catch (error) {
    console.log('delPlan -> error', error)
  }
}

// 保险计划书详情
export async function getPlanInfo (data) {
  try {
    const res = await http.post('/api/api/newPlanDetail', data)
    return res
  } catch (error) {
    console.log('getPlanInfo -> error', error)
  }
}

// 获取员工信息
export async function getSalesmanInfo (data) {
  try {
    const res = await http.post('/api/api/salesmanInfo', data)
    return res
  } catch (error) {
    console.log('getSalesmanInfo -> error', error)
  }
}
// 获取员工信息
export async function salesmanInfo (data) {
  try {
    const res = await http.post('/api/api/salesmanInfo', data)
    return res
  } catch (error) {
    console.log('delPlan -> error', error)
  }
}
// 获取订单详情
export async function getUserPolicyOrderInfo (data) {
  try {
    const res = await http.post('/api/api/getUserPolicyOrderInfo', data)
    return res
  } catch (err) {
    console.error(err)
    return err
  }
}
// 保费试算
// export async function calcCashVal (data) {
//   try {
//     const res = await http.post('/api/api/calcCashVal', data)
//     return res
//   } catch (err) {
//     console.error(err)
//     return err
//   }
// }
// 兼容版现价接口
export async function calcCashValCompatible (data) {
  try {
    const res = await http.post('/api/api/calcCashValCompatible', data)
    return res
  } catch (err) {
    console.error(err)
    return err
  }
}
