/* eslint-disable no-useless-escape */
/**
 * Created by Harris on 16/8/1.
 */
'use strict'
import Util from '@lcs/utils'
import BxBridge from '@/utils/BxBridge'
import { getQueryVariable } from '@/utils/common'
const ua = navigator.userAgent.toLowerCase()
export const isApp = ua.match(/bx_/) || ua.match(/cs_/)
var BxUtil = {}

// params
BxUtil.bxApp = (function () {
  const ua = window.navigator.userAgent
  var bxApp = false
  if (ua.match(/bx_/)) {
    const name = 'Qjbx'
    // const matched = ua.match(/bx_(\w+)_([\d.]+)/i)
    const matched = ua.match(/bx_(\w+)/i)
    const matched2 = ua.match(/bx_qjzs/)
    const platform = matched[1] === 'iOS' ? 'IOS' : (matched[1] === 'android' ? 'android' : 'unknown')
    const version = matched[2]

    bxApp = {
      appname: name,
      platform: platform,
      isAndroid: platform === 'android',
      isIOS: platform === 'IOS',
      isQJBX: true,
      isQJZS: matched2 || false,
      version: version
    }
  }
  return bxApp
})()
BxUtil.getQueryString = (name) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  if (window.location.hash.indexOf('?') < 0) {
    return null
  }
  const r = window.location.hash.split('?')[1].match(reg)
  if (r != null) return decodeURIComponent(r[2])
  return null
}
BxUtil.isQychart = function () {
  var ua = window.navigator.userAgent.toLowerCase()
  const wxwork = ua.match(/wxwork/i)
  console.log('BxUtil.isQychart -> wxwork', wxwork)
  return wxwork && wxwork.length && wxwork[0]
}
// callApp
BxUtil.callApp = (function () {
  'use strict'
  var doc = document
  var location = window.location
  var isQJBX = BxUtil.bxApp && BxUtil.bxApp.isQJBX
  var defaultUri = 'qjbxjumpnative://com.qijianbx.app.qjbclient/open'
  var os = Util.os
  var browser = Util.browser
  var ua = window.navigator.userAgent

  var iframe
  var defaultParams = {
    from: 'h5',
    href: location.href
  }

  function appendParams (params, extreParam = {}) {
    for (var key in extreParam) {
      // eslint-disable-next-line no-prototype-builtins
      if (extreParam.hasOwnProperty(key)) {
        params[key] = extreParam[key]
      }
    }
    return params
  }
  BxUtil.isQychart = function () {
    var ua = window.navigator.userAgent.toLowerCase()
    const wxwork = ua.match(/wxwork/i)
    console.log('BxUtil.isQychart -> wxwork', wxwork)
    return wxwork && wxwork.length && wxwork[0]
  }
  function buildUrl (options) {
    var url = Util.URL(options.url || defaultUri)
    var params = JSON.parse(url.params.params || '{}')
    params = appendParams(params, options.params)
    try {
      params = encodeURIComponent(JSON.stringify(params))
    } catch (e) {
      params = JSON.stringify(params)
    }
    url.params = {
      params: params
    }
    return url
  }

  function callInIframe (url) {
    if (!iframe) {
      iframe = doc.createElement('iframe')
      iframe.id = 'callapp_iframe_' + Date.now()
      iframe.frameborder = 0
      iframe.style.cssText = 'display: none;border:0;width:0;height:0;'
      doc.body.appendChild(iframe)
    }
    iframe.src = url
  }

  function setLocation (url, options) {
    if (options.replace !== false && (isQJBX || options.replace === true)) {
      location.replace(url)
    } else {
      location.href = url
    }
  }

  function useAnchorLink (url, options) {
    var a = document.createElement('a')
    a.setAttribute('href', url)
    a.style.display = 'none'
    document.body.appendChild(a)

    var e = document.createEvent('HTMLEvents')
    e.initEvent('click', false, false)
    a.dispatchEvent(e)
  }

  function GetHashParams (name) {
    var reg = new RegExp('(/?|^|&)' + name + '=([^&]*)(&|$)')
    var r = window.location.hash.substr(1).match(reg)
    if (r != null) return unescape(r[2])
    return null
  }

  function goPage (options) {
    var fr = options.params.fr || Util.params.search.fr || GetHashParams('fr')
    // 拷贝fr到剪切板
    if (fr && !isQJBX) {
      Util.copy(`【${Util.base64.encode(`fr=${fr}`)}】`)
    }
    options = options || {}
    if (typeof options.params === 'undefined') {
      options.params = appendParams({}, defaultParams)
    } else {
      options.params = appendParams(options.params, defaultParams)
    }

    var url = buildUrl(options)
    var isOriginalChrome =
      os.isAndroid && browser.isChrome && !browser.isWebview
    var fixUgly =
      os.isAndroid &&
      !!ua.match(/samsung/i) &&
      os.version.gte('4.3') &&
      os.version.lt('4.5')
    var ios9SafariFix = os.isIphone && os.version.gte('9.0') && browser.isSafari

    if (ios9SafariFix) {
      setTimeout(function () {
        useAnchorLink(url.toString(), options)
      }, 10)
    } else if (isQJBX || fixUgly || isOriginalChrome || os.isIOS) {
      setTimeout(function () {
        setLocation(url.toString(), options)
      }, 10)
    } else {
      callInIframe(url.toString())
    }

    // 添加微信环境和qq环境逻辑处理
    var isWeixin = Util.thirdApp.isWeixin
    var isQQ = Util.thirdApp.isQQ
    var isWeibo = Util.thirdApp.isWeibo
    if (isWeixin || isQQ || isWeibo) {
      if (window.location.href.indexOf('ul.sinalicaishi.com.cn/client/jump') > -1) {
        return
      }
      var noticeUrl = ''
      if (Util.os.isAndroid) {
        noticeUrl = `http://a.app.qq.com/o/simple.jsp?pkgname=cn.com.sina.licaishi.client&android_schema=${getSchema(options)}`
      } else {
        noticeUrl = `https://ul.sinalicaishi.com.cn/client/jump.html?params=${encodeURIComponent(JSON.stringify(options.params))}`
      }
      setTimeout(function () {
        window.location.href = noticeUrl
      }, 10)
    }
  }

  function justDownload (options = {}) {
    var fr = options.fr || Util.params.search.fr || GetHashParams('fr')
    const url = options.url || 'http://niu.sinalicaishi.com.cn/FE_vue_wap/supportWeixinQQ.html#/download?fr=' + options.fr
    Util.copy(`【${Util.base64.encode(`fr=${fr}`)}】`)
    window.open(url, '_blank')
  }

  function getSchema (options) {
    options = options || {}
    if (typeof options.params === 'undefined') {
      options.params = appendParams({}, defaultParams)
    } else {
      options.params = appendParams(options.params, defaultParams)
    }

    var url = buildUrl(options).toString()

    return url
  }

  return {
    goPage: goPage,
    getSchema: getSchema,
    justDownload: justDownload,
    appendParams: appendParams,
    buildUrl: buildUrl
  }
})()

let cookiesRes = ''

// const isQJBX = BxUtil.bxApp && BxUtil.bxApp.isQJBX

export async function getCookies (name) {
  const getCookieRes = cookiesRes[name] || ''
  try {
    return new Promise(function (resolve, reject) {
      if (isApp && !getCookieRes) {
        BxBridge.callByJS({
          name: 'getCookies',
          callback: function (ret) {
            console.log(ret, 'ret')
            cookiesRes = ret.data ? ret.data : ret
            console.log(cookiesRes[name], 'cookiesRes[name]')
            resolve(cookiesRes[name])
          }
        })
      } else {
        resolve(getCookieRes)
      }
      setTimeout(() => {
        resolve('')
      }, 200)
    })
  } catch (e) {
    e()
  }
}

BxUtil.getPlatform = () => {
  if (ua.match(/cs_/)) return '105'
  if (ua.match(/bx_/)) return '9'
  return getQueryVariable('platform') || '9'
}

export default BxUtil

/**
 * App环境跳转微信小程序
 * @param path 微信小程序页面路径
 * @param r_id 微信小程序 ID （默认广发保险经纪）
 */
export function jumpToMiniApp (path = '', r_id = 'gh_9b8c14ca0ea9') {
  BxBridge.callByJS({
    name: 'NativeUniversalJump',
    param: {
      rname: 'wx_minipro',
      params: { r_id, path }
    }
  })
}
