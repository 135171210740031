// 行为数据改造由神策替换成后端接口
import http from '@/utils/http'
import Util from '@lcs/utils'
const isPush = window.location.href.indexOf('is_push=1') !== -1 ? '1' : ''
const pushType = window.location.href.indexOf('push_type=1') !== -1 ? '微信文本' : (window.location.href.indexOf('push_type=2') !== -1 ? '微信图片' : (window.location.href.indexOf('push_type=3') !== -1 ? '微信图文消息' : (window.location.href.indexOf('push_type=4') !== -1 ? '模板消息' : '')))
// eslint-disable-next-line no-sparse-arrays
const pushTitle = decodeURIComponent((new RegExp('[?|&]' + 'push_title' + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [, ''])[1].replace(/\+/g, '%20')) || ''
const defaultOption = {
  v1_element_content: '',
  v1_message_title: '',
  v1_message_id: '',
  v1_message_type: '',
  v1_symbol: '',
  v1_stock_name: '',
  v1_lcs_name: '',
  v1_lcs_id: '',
  v1_source: '',
  v1_order: '',
  v1_share_channel: '',
  v1_paying_user: '',
  v1_page_title: '',
  v1_environment: Util.sinaApp.isLicaishi ? '0' : (Util.sinaApp.isMiniProgram ? '1' : (Util.sinaApp.isLicaishiAdmin ? '3' : (Util.sinaApp.isWeixin ? '5' : '2'))),
  v1_page_url: window.location.href,
  deviceId: '',
  sc_comments: '',
  visit_time: `${Date.now()}`.slice(0, 10),
  v1_is_push: isPush,
  v1_push_type: pushType,
  v1_push_title: pushTitle
}
async function saPost (data) {
  try {
    const res = await http.post('/socket/tracking/index', data, {
      netErrorTip: false
    })
    return res
  } catch (error) {
    return error
  }
}
export default class SaRequest {
  click (data) {
    this.point(data, 'BXClick')
  }

  visit (data) {
    this.point(data, 'BXVisit')
  }

  point (data, eventName) {
    const option = Object.assign(window.saRequestTdInitData || {}, defaultOption, data, { event: eventName })
    return saPost(option)
  }
}
