
import { wxLogin, wxAccessLogin, wxAccessLoginZy, RHwxLogin, externalUserInfo } from '@/api/weixin'
import { setToken, getRHWxInfo, setRHWxInfo, getWxInfo, setWxInfo } from '@/utils/auth'
import cookieUtils from '@lcs/utils/dist/cookieUtils'
import { getDouyinUserInfo, getDouyinUserInfoByToken } from '@/utils/login/server.js'
import BxUtil from '@/utils/BxUtil'
// const isBxApp = BxUtil.bxApp
const isQJZS = BxUtil.bxApp && BxUtil.bxApp.isQJZS
const getDefaultState = () => {
  return {
    qywxUserId: '', // 企业微信当前聊天外部联系人userId
    qywxUserInfo: {}, // 企业微信当前聊天外部联系人userInfo
    wxInfo: getWxInfo() || {},
    RHwxInfo: getRHWxInfo() || {}
  }
}
const state = getDefaultState()
const mutations = {
  SET_QYWX_USERID (state, data) {
    state.qywxUserId = data
  },
  SET_QYWX_USERINFO (state, data) {
    state.qywxUserInfo = data
  },
  ADD_WX_INFO (state, data) {
    if (data) data.expired = Date.now() + 48 * 3600 * 1000
    state.wxInfo = data
    if (data.user_info) {
      window.saRequestTdInitData = {
        encode_phone: data.user_info.encode_phone || '',
        uid: data.user_info.id || ''
      }
    }
    setWxInfo(data)
  },
  ADD_RH_WX_INFO (state, data) {
    if (data) data.expired = Date.now() + 48 * 3600 * 1000
    state.RHwxInfo = data
    setRHWxInfo(data)
  }
}

const actions = {
  setQywxUserId ({ commit }) {
    window.wx.invoke('getCurExternalContact', {
    }, async (res) => {
      if (res.err_msg === 'getCurExternalContact:ok') {
        commit('SET_QYWX_USERID', res.userId)
        const result = await externalUserInfo({ external_userid: res.userId })
        commit('SET_QYWX_USERINFO', result.data)
      } else {
        // 错误处理
      }
    })
  },
  setWxInfo: async function ({ commit }, params) {
    if (params) {
      commit('ADD_WX_INFO', params)
      setToken(params.access_token)
    }
  },
  douyinLogin: async function ({ commit }, { loginType = 'code', accessToken, code }) {
    let res
    if (loginType === 'code') {
      res = await getDouyinUserInfo({ code })
    } else if (loginType === 'accessToken') {
      res = await getDouyinUserInfoByToken()
    }
    if (res.code === 0) {
      commit('ADD_WX_INFO', res.data)
      setToken(res.data.access_token)
      return res
    } else {
      console.error('res.msg: ', res.msg)
    }
  },

  login: async function ({ dispatch, commit }, params) {
    params.platform = params.platform + ''
    if (params.platform === '32') {
      return await dispatch('douyinLogin', params)
    } else {
      return await dispatch('wxLogin', params)
    }
  },
  wxLogin: async function ({ commit }, params) {
    if (params.loginType === 'accessToken') {
      const res = isQJZS ? await wxAccessLoginZy(params) : await wxAccessLogin(params)
      console.log('登录返回信息: ', res)

      if (res.code === 0) {
        const data = res.data
        console.log('微信登录返回信息：', res.data)
        commit('ADD_WX_INFO', data)
        setToken(data.access_token)
        return data
      } else if (res.code === -1001) {
        localStorage.clear()
        cookieUtils.delCookie('access-token')
      }
    } else {
      const res = await wxLogin(params)
      console.log('进入微信登录接口')
      if (res.code === 0) {
        const data = res.data
        console.log('微信登录接口返回信息：', data)
        commit('ADD_WX_INFO', data)
        setToken(data.access_token)
        return data
      }
    }
  },
  // 融汇登录
  RHwxLogin: async function ({ commit }, param) {
    const res = await RHwxLogin(param)
    if (res.data.code === 1) {
      commit('ADD_RH_WX_INFO', res.data.data)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
