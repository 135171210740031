<!-- 微信授权弹窗 -->
<template>
  <div :class='["auth-popup-wrap", isCd ? "bg" : "" ]'
    :style="{'backgroundImage':`url(${pupupBg})`}" @click="next">
    <!-- <img :src="pupupBg" alt="" class="pupup_bg" @click="next"> -->
    <template v-if="!isCd">
      <div class="tip">
        <img
          src="https://fs.sylapp.cn/upload/20220811/202208111514282295ca3e9e9dac6ce66a5061c0c3928ffa22.png"
          alt="" class="icon">
        点击完成微信官方授权，体验完整服务
      </div>
      <div class="btn">
        下一步
      </div>
    </template>
    <template v-else>
      <div class="tip tip-cd">
        <img
          src="https://fs.sylapp.cn/upload/20220811/202208111514282295ca3e9e9dac6ce66a5061c0c3928ffa22.png"
          alt="" class="icon">
        点击完成微信官方授权，体验完整服务
      </div>
      <div class="btn btn-cd">
        下一步
      </div>
    </template>

  </div>
</template>

<script>
import { Popup, Button } from 'vant'
export default {
  name: 'authPopup',
  props: {
    platform: {
      type: [String, Number],
      default: 9
    }
  },
  data () {
    return {
      visible: true,
      cd: [1117, 1119]
    }
  },

  components: {
    [Popup.name]: Popup,
    [Button.name]: Button
  },

  computed: {
    pupupBg () {
      const lianTech = [27, 38]
      const insur = [9, 18]
      let coverUrl = ''
      if (this.cd.includes(+this.platform)) {
        coverUrl = 'https://fs.sylapp.cn/appimupload/20221118/1668740241357/16687402409866455底图-1624.jpg'
      }
      if (lianTech.includes(+this.platform)) {
        coverUrl = 'https://fs.sylapp.cn/upload/20220829/202208291706367381875e8f632dd1a523377dd8661bbd4273.png'
      }
      if (insur.includes(+this.platform)) {
        coverUrl = 'https://fs.sylapp.cn/upload/20220829/20220829170555533c8e57d7ac9b95eb6d882db76d324c1e53.png'
      }
      return coverUrl
    },
    isCd () {
      if (this.cd.includes(+this.platform)) {
        return true
      }
      return false
    }

  },
  mounted () {
    this.bodyScroll()
  },
  methods: {

    bodyScroll (event) {
      document.body.style.overflow = 'hidden'
      document.body.style.height = '100vh'
    },
    setPopupVisible (visible) {
      this.visible = visible
    },
    next () {
      this.setPopupVisible(false)
      this.$emit('next')
    }
  }
}

</script>
<style lang='less' scoped>
.auth-popup-wrap {
  background-size: 100% 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 99999;
  .tip {
    position: absolute;
    z-index: inherit;
    bottom: 198px;
    width: 100%;
    display: flex;
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #171819;
    line-height: 32px;
    justify-content: center;
    align-items: center;
    .icon {
      width: 46px;
      height: 38px;
      margin-right: 15px;
    }
  }
  .tip-cd {
    position: absolute;
    z-index: inherit;
    bottom: 229px;
    width: 100%;
    display: flex;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: white;
    line-height: 32px;
    justify-content: center;
    align-items: center;
    .icon {
      width: 41px;
      height: 33px;
      margin-right: 15px;
    }
  }
  .btn {
    width: 378px;
    height: 75px;
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 45px;
    position: absolute;
    left: 50%;
    display: flex;
    align-items: center;
    background: #57be6a;
    border-radius: 15px;
    justify-content: center;
    transform: translateX(-50%);
    bottom: 99px;
  }
  .btn-cd {
    width: 630px;
    height: 100px;
    background: #ffffff;
    border-radius: 10px;
    font-size: 40px;
    font-family: OPPOSans;
    font-weight: 500;
    color: #fa2725;
    line-height: 46px;
  }
  .pupup_bg {
    height: 100vh;
    width: 100vw;
  }
}
.bg{
  background-size: cover;
}
</style>
