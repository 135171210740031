const state = {
  msgList: [],
  pushInfo: {},
  msgType: 'list',
  activityData: {},
  newMsgData: {}
}

const mutations = {
  CLEAR_MSG_LIST: (state) => {
    state.msgList = []
  },
  SET_MSG_LIST: (state, list) => {
    if (state.msgList.length >= 510) {
      return false
    }
    const array = list.concat(state.msgList)
    // state.msgList = list.concat(state.msgList)
    // 消息进行去重
    const arr = []
    for (const item of array) {
      let flag = false
      for (const v of arr) {
        if (item.msg_id === v.msg_id) {
          flag = true
          break
        }
      }
      if (!flag) {
        arr.push(item)
      }
    }
    state.msgList = arr
  },
  REPLACE_MSG: (state, data) => {
    state.newMsgData = data
  },
  MSG_ADD: (state, msg) => {
    if (!msg) return
    const msgString = JSON.stringify(state.msgList)
    const list = []
    for (const item of msg) {
      if (msgString.indexOf(item.msg_id) === -1) {
        list.push(item)
      }
    }
    if (!list.length) return
    state.msgList = state.msgList.concat(msg)
    if (state.msgList.length >= 600) {
      state.msgList.splice(0, 90)
    }
  },
  SET_MSG_TYPE: (state, type) => {
    state.msgType = type
  },
  SET_PUSH_INFO: (state, info) => {
    if (!Array.isArray(info)) {
      let isRepeat = false
      for (const i in state.pushInfo) {
        if (state.pushInfo[i].type === info.type) {
          isRepeat = true
          state.pushInfo[i] = info
        }
      }
      if (!isRepeat) {
        state.pushInfo.push(info)
      }
    } else {
      state.pushInfo = info
    }
    state.pushInfo.splice(0, 0)
  },
  SET_ACT_DATA: (state, data) => {
    state.activityData = data
  }
}

const actions = {
  setMsgList ({ commit }, { list, type = 'list' }) {
    commit('SET_MSG_LIST', list)
    commit('SET_MSG_TYPE', type)
  },
  replaceMsg ({ commit }, data) {
    commit('REPLACE_MSG', data)
  },
  msgAdd ({ commit }, { msg, type = 'single' }) {
    commit('MSG_ADD', msg)
    commit('SET_MSG_TYPE', type)
  },
  setMsgType ({ commit }, type) {
    commit('SET_MSG_TYPE', type)
  },
  setPushInfo ({ commit }, info) {
    commit('SET_PUSH_INFO', info)
  },
  activityAdd ({ commit }, data) {
    commit('SET_ACT_DATA', data)
  },
  clearMsgList ({ commit }, data) {
    commit('CLEAR_MSG_LIST', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
