import Vue from 'vue'
import 'lib-flexible'
import '@/assets/css/reset.css'
import 'flexboxgrid/dist/flexboxgrid.min.css'
import App from './App.vue'
import router from './router'
import config from '@/config'
import SaApi from '@/api/saApi'
import store from './store'
import loadVconsole from '@/utils/vconsole'
import { Popup, Field } from 'mint-ui'
import VueClipboard from 'vue-clipboard2'

import BxUtil from '@/utils/BxUtil'
// 微信分享
import { wxConfig, wxInit } from '@/utils/wxConfig'
import { LcsToast, LcsDialog, LcsPickerPlus } from '@lcs/popup'
import LinkJump from '@/utils/Linkjump.js'
import BxBridge from '@/utils/BxBridge'
import SaRequest from '@/api/saApi/sa_request-td-init'
import LcsAuthorization from '@/components/authorization'
export const BX_VERSION = '81'
// 接口请求埋点
Vue.prototype.$sa_request = new SaRequest()
// eslint-disable-next-line no-new
new LinkJump({ router, BxBridge })

Vue.component(Popup.name, Popup)
Vue.component(Field.name, Field)
// todo 项目版本号，修改后会强行刷新local数据
Vue.prototype.bxVersion = BX_VERSION
Vue.prototype.wxConfig = wxConfig
Vue.prototype.wxInit = wxInit
wxInit()
Vue.prototype.$BxUtil = BxUtil
Vue.use(SaApi, config.saApi)
Vue.use(LcsToast)
Vue.use(LcsDialog)
Vue.use(LcsPickerPlus)
Vue.use(VueClipboard)
Vue.use(LcsAuthorization)
loadVconsole()
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
