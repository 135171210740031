import storageUtils from '@lcs/utils/dist/storageUtils'
import cookieUtils from '@lcs/utils/dist/cookieUtils'
import BxUtil from '@/utils/BxUtil'
const TokenKey = 'access-token'
const WxInfoKey = 'bx-wx-info'
const RHWxInfoKey = 'rh-wx-info'
/**
 * @param {*} getToken
 * @return {string} token
 */
export const getToken = () => {
  return getParams('accessToken') || storageUtils.get(TokenKey) || cookieUtils.getCookie(TokenKey)
}

export const setToken = (token) => {
  cookieUtils.setCookie(TokenKey, token, new Date(Date.now() + 48 * 3600 * 1000))
  return storageUtils.set(TokenKey, token)
}

export const removeToken = () => {
  cookieUtils.delCookie(TokenKey)
  return storageUtils.remove(TokenKey)
}

// wxInfo
export const getWxInfo = () => {
  return storageUtils.get(WxInfoKey)
}
export const setWxInfo = (data) => {
  return storageUtils.set(WxInfoKey, data)
}
export const removeWxInfo = () => {
  return storageUtils.remove(WxInfoKey)
}

// 融汇wxInfo
export const getRHWxInfo = () => {
  return storageUtils.get(RHWxInfoKey)
}
export const setRHWxInfo = (data) => {
  return storageUtils.set(RHWxInfoKey, data)
}
export const removeRHWxInfo = () => {
  return storageUtils.remove(RHWxInfoKey)
}

// eslint-disable-next-line no-unused-vars
function getParams (params) {
  // eslint-disable-next-line no-sparse-arrays
  return decodeURIComponent((new RegExp('[?|&]' + params + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [, ''])[1].replace(/\+/g, '%20')) || ''
}
export const getPlatform = () => {
  if (BxUtil.bxApp) {
    return 9
  }
  return storageUtils.get('platform')
}
export const setPlatform = (val) => {
  return storageUtils.set('platform', val)
}
export const removePlatform = (val) => {
  return storageUtils.remove('platform')
}
